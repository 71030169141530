export interface IDevice {
  device_hash: string;
  user_id: string;
  id?: string;
}

export interface IDeviceWithId extends IDevice {
  _id: string;
}

export enum DeviceError {
  MAX_DEVICES = 'Max device',
  DEVICE_NOT_FOUND = 'Device not found',
}
