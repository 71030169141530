<xxx-vpn-header></xxx-vpn-header>

<div class="eula eula-text">
  <h1>END USER LICENCE AGREEMENT</h1>
  <p>Last updated June 29, 2023</p>
  <p>
    ViVPN is licensed to You (End-User) by GLOBAL-77 LLC, located and registered
    at 13, apt. 39, Argishtii Ave., Yerevan, 0004, Armenia ('Licensor'), for use
    only under the terms of this Licence Agreement.
  </p>
  <p>
    By downloading the Licensed Application from Apple's software distribution
    platform ('App Store') and Google's software distribution platform ('Play
    Store'), and any update thereto (as permitted by this Licence Agreement),
    You indicate that You agree to be bound by all of the terms and conditions
    of this Licence Agreement, and that You accept this Licence Agreement. App
    Store and Play Store are referred to in this Licence Agreement as
    'Services'.
  </p>
  <p>
    The parties of this Licence Agreement acknowledge that the Services are not
    a Party to this Licence Agreement and are not bound by any provisions or
    obligations with regard to the Licensed Application, such as warranty,
    liability, maintenance and support thereof. GLOBAL-77 LLC, not the Services,
    is solely responsible for the Licensed Application and the content thereof.
  </p>
  <p>
    This Licence Agreement may not provide for usage rules for the Licensed
    Application that are in conflict with the latest Apple Media Services Terms
    and Conditions and Google Play Terms of Service ('Usage Rules'). GLOBAL-77
    LLC acknowledges that it had the opportunity to review the Usage Rules and
    this Licence Agreement is not conflicting with them.
  </p>
  <p>
    ViVPN when purchased or downloaded through the Services, is licensed to You
    for use only under the terms of this Licence Agreement. The Licensor
    reserves all rights not expressly granted to You. ViVPN is to be used on
    devices that operate with Apple's operating systems ('iOS' and 'Mac OS') or
    Google's operating system ('Android').
  </p>
  <h2>TABLE OF CONTENTS</h2>
  <ul>
    <li>1. THE APPLICATION</li>
    <li>2. SCOPE OF LICENCE</li>
    <li>3. TECHNICAL REQUIREMENTS</li>
    <li>4. MAINTENANCE AND SUPPORT</li>
    <li>5. USE OF DATA</li>
    <li>6. USER-GENERATED CONTRIBUTIONS</li>
    <li>7. CONTRIBUTION LICENCE</li>
    <li>8. LIABILITY</li>
    <li>9. WARRANTY</li>
    <li>10. PRODUCT CLAIMS</li>
    <li>11. LEGAL COMPLIANCE</li>
    <li>12. CONTACT INFORMATION</li>
    <li>13. TERMINATION</li>
    <li>14. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY</li>
    <li>15. INTELLECTUAL PROPERTY RIGHTS</li>
    <li>16. APPLICABLE LAW</li>
    <li>17. MISCELLANEOUS</li>
  </ul>
  <h2>1. THE APPLICATION</h2>
  <p>
    ViVPN ('Licensed Application') is a piece of software created to create
    secure connection — and customised for iOS and Android mobile devices
    ('Devices'). It is used to create secure connection to the internet.
  </p>
  <p>
    The Licensed Application is not tailored to comply with industry-specific
    regulations (Health Insurance Portability and Accountability Act (HIPAA),
    Federal Information Security Management Act (FISMA), etc.), so if your
    interactions would be subjected to such laws, you may not use this Licensed
    Application. You may not use the Licensed Application in a way that would
    violate the Gramm-Leach-Bliley Act (GLBA).
  </p>
  <h2>2. SCOPE OF LICENCE</h2>
  <p>
    2.1 You are given a non-transferable, non-exclusive, non-sublicensable
    licence to install and use the Licensed Application on any Devices that You
    (End-User) own or control and as permitted by the Usage Rules, with the
    exception that such Licensed Application may be accessed and used by other
    accounts associated with You (End-User, The Purchaser) via Family Sharing or
    volume purchasing.
  </p>
  <p>
    2.2 This licence will also govern any updates of the Licensed Application
    provided by Licensor that replace, repair, and/or supplement the first
    Licensed Application, unless a separate licence is provided for such update,
    in which case the terms of that new licence will govern.
  </p>
  <p>
    2.3 You may not share or make the Licensed Application available to third
    parties (unless to the degree allowed by the Usage Rules, and with GLOBAL-77
    LLC's prior written consent), sell, rent, lend, lease or otherwise
    redistribute the Licensed Application.
  </p>
  <p>
    2.4 You may not reverse engineer, translate, disassemble, integrate,
    decompile, remove, modify, combine, create derivative works or updates of,
    adapt, or attempt to derive the source code of the Licensed Application, or
    any part thereof (except with GLOBAL-77 LLC's prior written consent).
  </p>
  <p>
    2.5 You may not copy (excluding when expressly authorised by this licence
    and the Usage Rules) or alter the Licensed Application or portions thereof.
    You may create and store copies only on devices that You own or control for
    backup keeping under the terms of this licence, the Usage Rules, and any
    other terms and conditions that apply to the device or software used. You
    may not remove any intellectual property notices. You acknowledge that no
    unauthorised third parties may gain access to these copies at any time. If
    you sell your Devices to a third party, you must remove the Licensed
    Application from the Devices before doing so.
  </p>
  <p>
    2.6 Violations of the obligations mentioned above, as well as the attempt of
    such infringement, may be subject to prosecution and damages.
  </p>
  <p>
    2.7 Licensor reserves the right to modify the terms and conditions of
    licensing.
  </p>
  <p>
    2.8 Nothing in this licence should be interpreted to restrict third-party
    terms. When using the Licensed Application, You must ensure that You comply
    with applicable third-party terms and conditions.
  </p>
  <h2>3. TECHNICAL REQUIREMENTS</h2>
  <h2>4. MAINTENANCE AND SUPPORT</h2>
  <p>
    4.1 The Licensor is solely responsible for providing any maintenance and
    support services for this Licensed Application. You can reach the Licensor
    at the email address listed in the App Store or Play Store Overview for this
    Licensed Application.
  </p>
  <p>
    4.2 GLOBAL-77 LLC and the End-User acknowledge that the Services have no
    obligation whatsoever to furnish any maintenance and support services with
    respect to the Licensed Application.
  </p>
  <h2>5. USE OF DATA</h2>
  <p>
    You acknowledge that Licensor will be able to access and adjust Your
    downloaded Licensed Application content and Your personal information, and
    that Licensor's use of such material and information is subject to Your
    legal agreements with Licensor and Licensor's privacy policy.
  </p>
  <p>
    You acknowledge that the Licensor may periodically collect and use technical
    data and related information about your device, system, and application
    software, and peripherals, offer product support, facilitate the software
    updates, and for purposes of providing other services to you (if any)
    related to the Licensed Application. Licensor may also use this information
    to improve its products or to provide services or technologies to you, as
    long as it is in a form that does not personally identify you.
  </p>
  <h2>6. USER-GENERATED CONTRIBUTIONS</h2>
  <p>
    The Licensed Application does not offer users to submit or post content. We
    may provide you with the opportunity to create, submit, post, display,
    transmit, perform, publish, distribute, or broadcast content and materials
    to us or in the Licensed Application, including but not limited to text,
    writings, video, audio, photographs, graphics, comments, suggestions, or
    personal information or other material (collectively, 'Contributions').
    Contributions may be viewable by other users of the Licensed Application and
    through third-party websites or applications. As such, any Contributions you
    transmit may be treated in accordance with the Licensed Application Privacy
    Policy. When you create or make available any Contributions, you thereby
    represent and warrant that:
  </p>
  <p>
    1. The creation, distribution, transmission, public display, or performance,
    and the accessing, downloading, or copying of your Contributions do not and
    will not infringe the proprietary rights, including but not limited to the
    copyright, patent, trademark, trade secret, or moral rights of any third
    party.
  </p>
  <p>
    2. You are the creator and owner of or have the necessary licences, rights,
    consents, releases, and permissions to use and to authorise us, the Licensed
    Application, and other users of the Licensed Application to use your
    Contributions in any manner contemplated by the Licensed Application and
    this Licence Agreement.
  </p>
  <p>
    3. You have the written consent, release, and/or permission of each and
    every identifiable individual person in your Contributions to use the name
    or likeness or each and every such identifiable individual person to enable
    inclusion and use of your Contributions in any manner contemplated by the
    Licensed Application and this Licence Agreement.
  </p>
  <p>4. Your Contributions are not false, inaccurate, or misleading.</p>
  <p>
    5. Your Contributions are not unsolicited or unauthorised advertising,
    promotional materials, pyramid schemes, chain letters, spam, mass mailings,
    or other forms of solicitation.
  </p>
  <p>
    6. Your Contributions are not obscene, lewd, lascivious, filthy, violent,
    harassing, libellous, slanderous, or otherwise objectionable (as determined
    by us).
  </p>
  <p>
    7. Your Contributions do not ridicule, mock, disparage, intimidate, or abuse
    anyone.
  </p>
  <p>
    8. Your Contributions are not used to harass or threaten (in the legal sense
    of those terms) any other person and to promote violence against a specific
    person or class of people.
  </p>
  <p>
    9. Your Contributions do not violate any applicable law, regulation, or
    rule.
  </p>
  <p>
    10. Your Contributions do not violate the privacy or publicity rights of any
    third party.
  </p>
  <p>
    11. Your Contributions do not violate any applicable law concerning child
    pornography, or otherwise intended to protect the health or well-being of
    minors.
  </p>
  <p>
    12. Your Contributions do not include any offensive comments that are
    connected to race, national origin, gender, sexual preference, or physical
    handicap.
  </p>
  <p>
    13. Your Contributions do not otherwise violate, or link to material that
    violates, any provision of this Licence Agreement, or any applicable law or
    regulation.
  </p>
  <p>
    Any use of the Licensed Application in violation of the foregoing violates
    this Licence Agreement and may result in, among other things, termination or
    suspension of your rights to use the Licensed Application.
  </p>
  <h2>7. CONTRIBUTION LICENCE</h2>
  <p>
    You agree that we may access, store, process, and use any information and
    personal data that you provide following the terms of the Privacy Policy and
    your choices (including settings).
  </p>
  <p>
    By submitting suggestions of other feedback regarding the Licensed
    Application, you agree that we can use and share such feedback for any
    purpose without compensation to you.
  </p>
  <p>
    We do not assert any ownership over your Contributions. You retain full
    ownership of all of your Contributions and any intellectual property rights
    or other proprietary rights associated with your Contributions. We are not
    liable for any statements or representations in your Contributions provided
    by you in any area in the Licensed Application. You are solely responsible
    for your Contributions to the Licensed Application and you expressly agree
    to exonerate us from any and all responsibility and to refrain from any
    legal action against us regarding your Contributions.
  </p>
  <h2>8. LIABILITY</h2>
  <h2>9. WARRANTY</h2>
  <p>
    9.1 Licensor warrants that the Licensed Application is free of spyware,
    trojan horses, viruses, or any other malware at the time of Your download.
    Licensor warrants that the Licensed Application works as described in the
    user documentation.
  </p>
  <p>
    9.2 No warranty is provided for the Licensed Application that is not
    executable on the device, that has been unauthorisedly modified, handled
    inappropriately or culpably, combined or installed with inappropriate
    hardware or software, used with inappropriate accessories, regardless if by
    Yourself or by third parties, or if there are any other reasons outside of
    GLOBAL-77 LLC's sphere of influence that affect the executability of the
    Licensed Application.
  </p>
  <p>
    9.3 You are required to inspect the Licensed Application immediately after
    installing it and notify GLOBAL-77 LLC about issues discovered without delay
    by email provided in Contact Information. The defect report will be taken
    into consideration and further investigated if it has been emailed within a
    period of 30 days after discovery.
  </p>
  <p>
    9.4 If we confirm that the Licensed Application is defective, GLOBAL-77 LLC
    reserves a choice to remedy the situation either by means of solving the
    defect or substitute delivery.
  </p>
  <p>
    9.5 In the event of any failure of the Licensed Application to conform to
    any applicable warranty, You may notify the Services Store Operator, and
    Your Licensed Application purchase price will be refunded to You. To the
    maximum extent permitted by applicable law, the Services Store Operator will
    have no other warranty obligation whatsoever with respect to the Licensed
    Application, and any other losses, claims, damages, liabilities, expenses,
    and costs attributable to any negligence to adhere to any warranty.
  </p>
  <p>
    9.6 If the user is an entrepreneur, any claim based on faults expires after
    a statutory period of limitation amounting to twelve (12) months after the
    Licensed Application was made available to the user. The statutory periods
    of limitation given by law apply for users who are consumers.
  </p>
  <h2>10. PRODUCT CLAIMS</h2>
  <p>
    GLOBAL-77 LLC and the End-User acknowlege that GLOBAL-77 LLC, and not the
    Services, is responsible for addressing any claims of the End-User or any
    third party relating to the Licensed Application or the End-User's
    possession and/or use of that Licensed Application, including, but not
    limited to:
  </p>
  <p>(i) product liability claims;</p>
  <p>
    (ii) any claim that the Licensed Application fails to conform to any
    applicable legal or regulatory requirement; and
  </p>
  <p>
    (iii) claims arising under consumer protection, privacy, or similar
    legislation, including in connection with Your Licensed Application’s use of
    the HealthKit and HomeKit.
  </p>
  <h2>11. LEGAL COMPLIANCE</h2>
  <p>
    You represent and warrant that You are not located in a country that is
    subject to a US Government embargo, or that has been designated by the US
    Government as a 'terrorist supporting' country; and that You are not listed
    on any US Government list of prohibited or restricted parties.
  </p>
  <h2>12. CONTACT INFORMATION</h2>
  <p>
    For general inquiries, complaints, questions or claims concerning the
    Licensed Application, please contact:
  </p>
  <p>
    Adminstrator<br />
    13, apt. 39, Argishtii Ave.<br />
    Yerevan, 0004<br />
    ARMENIA<br />
    info&#64;vivpnxxx.com
  </p>
  <h2>13. TERMINATION</h2>
  <p>
    The licence is valid until terminated by GLOBAL-77 LLC or by You. Your
    rights under this licence will terminate automatically and without notice
    from GLOBAL-77 LLC if You fail to adhere to any term(s) of this licence.
    Upon Licence termination, You shall stop all use of the Licensed
    Application, and destroy all copies, full or partial, of the Licensed
    Application.
  </p>
  <h2>14. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY</h2>
  <p>
    GLOBAL-77 LLC represents and warrants that GLOBAL-77 LLC will comply with
    applicable third-party terms of agreement when using Licensed Application.
  </p>
  <p>
    In Accordance with Section 9 of the 'Instructions for Minimum Terms of
    Developer's End-User Licence Agreement', both Apple and Google and their
    subsidiaries shall be third-party beneficiaries of this End User Licence
    Agreement and — upon Your acceptance of the terms and conditions of this
    Licence Agreement, both Apple and Google will have the right (and will be
    deemed to have accepted the right) to enforce this End User Licence
    Agreement against You as a third-party beneficiary thereof.
  </p>
  <h2>15. INTELLECTUAL PROPERTY RIGHTS</h2>
  <p>
    GLOBAL-77 LLC and the End-User acknowledge that, in the event of any
    third-party claim that the Licensed Application or the End-User's possession
    and use of that Licensed Application infringes on the third party's
    intellectual property rights, GLOBAL-77 LLC, and not the Services, will be
    solely responsible for the investigation, defence, settlement, and discharge
    or any such intellectual property infringement claims.
  </p>
  <h2>16. APPLICABLE LAW</h2>
  <p>
    This Licence Agreement is governed by the laws of Armenia excluding its
    conflicts of law rules.
  </p>
  <h2>17. MISCELLANEOUS</h2>
  <p>
    17.1 If any of the terms of this agreement should be or become invalid, the
    validity of the remaining provisions shall not be affected. Invalid terms
    will be replaced by valid ones formulated in a way that will achieve the
    primary purpose.
  </p>
</div>
