import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterLink } from '@angular/router';

import { IUserEmailAndPassword } from '@xxx-vpn/api-interfaces';
import { MessageService } from 'primeng/api';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { take } from 'rxjs/operators';

import { SignUpService } from './sign-up.service';

@Component({
  selector: 'xxx-vpn-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [MessageService],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterLink,
    ToastModule,
    NgIf,
    ProgressSpinnerModule,
  ],
})
export class SignUpComponent {
  public signUpForm = new FormGroup({
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });
  loading = false;

  constructor(
    private readonly signUpService: SignUpService,
    private readonly messageService: MessageService,
    private readonly router: Router,
  ) {}

  public signUp() {
    const { email, password } = this.signUpForm.value;
    const user = { email, password } as IUserEmailAndPassword;
    this.loading = true;
    this.signUpService
      .registerUser(user)
      .pipe(take(1))
      .subscribe({
        next: () => this.router.navigateByUrl('/sign-in').catch(),
        error: (err) => {
          this.loading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Oшибка',
            detail: err.error.message,
          });
        },
      });
  }
}
