export interface ICertificate {
  /** @deprecated */
  device?: string;
  user_id: string;
  server_id?: string;
  certificate: string;
  traffic?: number;
  limit?: number;
  status?: boolean;
}

export interface ICertificateWithId extends ICertificate {
  _id: string;
}
