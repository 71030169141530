import mongoose from 'mongoose';

import { IPool } from './pool.interface';
/**
 * Интерфейс для сервера
 */

export interface IServer {
  /** id сервера */
  id: string;
  /** ip сервера */
  ip: string;
  /** порт сервера */
  port: string;
  /** ключ сервера */
  key: string;
  /** сертификат сервера */
  certSha: string;
  /** количество сертификатов на сервере */
  keyCount: number;
  /** количество недавно активных сертификатов на сервере */
  activeKeyCount: number;
  /** максимальное количество ключей */
  maxKeysCount?: number;
  /** размер сервера */
  sizeSlug?: string;
  /** название сервера */
  name: string;
  /** страна сервера */
  country: string;
  /** пул сервера */
  pool?: IPool;
  /** id пула */
  pool_id?: mongoose.Types.ObjectId;
  /** трафик сервера */
  traffic?: number;
  /** дата создания */
  createdAt?: Date;
  /** дата обновления */
  updatedAt?: Date;
  /** заблокирован ли сервер */
  isBlocked?: boolean;
  /** активен ли сервер */
  isActive?: boolean;
  /** достигнут ли лимит сервера */
  limitReached?: boolean;
}

export interface ITransfer {
  bytesTransferredByUserId: {
    [key: string]: number;
  };
}
