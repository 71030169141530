<div class="sign-in">
  <div class="logo">
    <img src="/assets/images/logo.png" alt="" />
  </div>
  <form class="form" [formGroup]="form" autocomplete="on">
    <div class="form-title">Sign In</div>
    <div class="invalid-status" *ngIf="message()">{{ message() }}</div>
    <div class="form-wrapper">
      <div class="form-fields">
        <label class="form-fields-label">E-mail</label>
        <input
          class="form-fields-input"
          type="text"
          name="email"
          formControlName="email"
          autocomplete="username"
          placeholder="example@example.com"
        />
      </div>

      <div class="form-fields">
        <label class="form-fields-label">Password</label>
        <input
          class="form-fields-input"
          type="text"
          #passwordInput
          name="password"
          autocomplete="current-password"
          (focus)="passwordInput.type = 'password'"
          formControlName="password"
          placeholder="********"
        />
        <a class="form-forgot" href="/forgot-password">Forgot password?</a>
      </div>
    </div>
    <div class="submit">
      <div class="btn-outline" [class.loading]="loading" (click)="auth()">
        <p-progressSpinner
          *ngIf="loading"
          [style]="{ height: '40px', width: '40px' }"
        ></p-progressSpinner>
        Sign In
      </div>
    </div>
  </form>
  <div class="links">
    <span>Dont’t have an account?</span>

    <a class="links-item" [routerLink]="['/sign-up']">
      <span>Sign up ></span>
    </a>
  </div>
</div>
