export interface IPagination {
  page: number;
  pageSize: number;
  offset?: number;
  sort?: string;
  filter?: string;
}

export type IPaginatedResource<T> = {
  totalItems: number;
  items: T[];
  page: number;
  pageSize: number;
};
