import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import { RouterLink } from '@angular/router';

import { TranslateService, TranslateModule } from '@ngx-translate/core';

import { HeaderComponent } from '../../components/header/header.component';
import { TariffsComponent } from '../../components/tariffs/tariffs.component';

@Component({
  selector: 'xxx-vpn-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterLink,
    NgIf,
    TariffsComponent,
    TranslateModule,
    HeaderComponent,
  ],
})
export class LandingPage {
  private readonly translateService = inject(TranslateService);
  currentLanguage = signal<string>(
    this.translateService.currentLang || this.translateService.defaultLang,
  );

  scrollToTariffs() {
    const tariffs = document.getElementById('tariffs');
    tariffs?.scrollIntoView({ behavior: 'smooth' });
  }
}
