export enum EDOServerCommand {
  INSTALL_OUTLINE = 1,
}

export enum EDOServerCommandStatus {
  FAILED = -1,
  CREATED = 0,
  INPROGRESS = 50,
  FINISHED = 100,
}

export interface IDOShellTask {
  id?: string;
  server_id: number;
  status: EDOServerCommandStatus;
  task: EDOServerCommand;
}
