import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IUserEmailAndPassword, IWrapper } from '@xxx-vpn/api-interfaces';
import { map, Observable, filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SignInService {
  constructor(private http: HttpClient) {
    //
  }

  signInUser(
    user: IUserEmailAndPassword,
  ): Observable<IWrapper<{ token: string }>> {
    const url = '/api/auth/login';

    return this.http.post<IWrapper<{ token: string }>>(url, user).pipe(
      filter((res) => res.status),
      map((res) => {
        if (res.data) {
          localStorage.setItem('token', res.data.token);
        }
        return res;
      }),
    );
  }
}
