import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { IUser, IWrapper, Role } from '@xxx-vpn/api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private readonly router: Router,
    private readonly http: HttpClient,
  ) {}

  async canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      const token = localStorage.getItem('token');
      if (!token) {
        this.router.navigate(['/sign-in']);
        resolve(false);
      }

      this.http
        .get<IWrapper<IUser>>('/api/user/me', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .toPromise()
        .then((me) => {
          resolve(true);
        })
        .catch((err) => {
          this.router.navigate(['/sign-in']);
          resolve(false);
        });
    });
  }
}
