<div class="tariffs" id="tariffs">
  <div class="tariffs-title">{{ 'landing.choose' | translate }}:</div>

  <div class="tariffs-wrapper">
    <div
      *ngFor="let tariff of tariffs"
      [class.active]="activeTariff === tariff.type"
      (click)="activeTariff = tariff.type"
      class="tariffs-card"
    >
      <img class="tariffs-card-image" [src]="tariff.image" alt="" />
      <div class="tariffs-card-cost">
        <div class="tariffs-card-month">{{ tariff.title | translate }}</div>
        <div class="tariffs-card-cost-actions">
          <div class="tariffs-card-price">{{ tariff.price | translate }}</div>
          <div>
            <span class="tariffs-card-currency"
              >{{ 'landing.price' | translate }}
            </span>
            <span class="tariffs-card-premonth">
              {{ 'landing.permonth' | translate }}</span
            >
          </div>
        </div>
        <div class="tariffs-card-sale">
          <span *ngIf="tariff.type !== 1">
            {{
              'account.saving'
                | translate : { saving: tariff.saving | translate }
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
