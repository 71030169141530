import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

import { ModalComponent } from '../../components/modal/modal.component';
import { SignUpComponent } from '../../components/sign-up/sign-up.component';

@Component({
  selector: 'xxx-vpn-sign-up-page',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ModalComponent, SignUpComponent],
})
export class SignUpPage {
  constructor(private readonly router: Router) {
    //
  }

  public closeSignUp() {
    this.router.navigate(['/']);
  }
}
