<div class="profile">
  <div class="profile-header">
    <a [routerLink]="['/']">
      <img src="/assets/images/nav-icons.svg" alt="back" />
    </a>
    <div class="profile-title">
      <span>{{ 'profile.title' | translate }}</span>
    </div>
  </div>

  <div>
    <div class="profile-email">
      {{ user()?.email }}
    </div>

    <ng-container *ngIf="devices()">
      <div class="profile-devices-title">
        {{ 'profile.devices' | translate }}: {{ devices()?.length }}/5
      </div>
      <div class="profile-devices-actions">
        <ng-container *ngFor="let device of devices()">
          <div class="profile-devices-row">
            <span>{{ device.device_hash }}</span>
            <div
              class="profile-devices-row-delete"
              (click)="removeDevice(device?.id ?? null)"
            >
              <img src="/assets/images/close.svg" alt="" />
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <div class="profile-actions">
    <ng-container *ngIf="user()?.role === 'admin'">
      <a class="profile-exit" [routerLink]="['/admin']">
        <span class="p-button-label text-white">Admin panel</span>
      </a>
    </ng-container>
    <span class="profile-exit" (click)="exit()">{{
      'profile.exit' | translate
    }}</span>
  </div>
</div>

<p-toast></p-toast>
