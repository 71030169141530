import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

interface ITariff {
  price: string;
  saving: string;
  type: number;
  image: string;
  title: string;
}

@Component({
  selector: 'xxx-vpn-tariffs',
  templateUrl: './tariffs.component.html',
  styleUrls: ['./tariffs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule, NgOptimizedImage, CommonModule],
})
export class TariffsComponent {
  public activeTariff = 1;

  readonly tariffs: ITariff[] = [
    {
      title: 'landing.1months',
      price: 'tariffs.price.one_month',
      saving: 'tariffs.saving.one_month',
      type: 1,
      image: '/assets/images/LooperGroup2.png',
    },
    {
      title: 'landing.6months',
      price: 'tariffs.price.six_months',
      saving: 'tariffs.saving.six_months',
      type: 6,
      image: '/assets/images/LooperGroup3.png',
    },
    {
      title: 'landing.12months',
      price: 'tariffs.price.one_year',
      saving: 'tariffs.saving.one_year',
      type: 12,
      image: '/assets/images/LooperGroup.png',
    },
  ];
}
