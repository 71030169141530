import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IWrapper } from '@xxx-vpn/api-interfaces';
import { Observable } from 'rxjs';

@Injectable()
export class ForgotService {
  constructor(private http: HttpClient) {}

  forgotPassword(data: { email: string }): Observable<IWrapper<boolean>> {
    const url = '/api/auth/reset-password';

    return this.http.post<IWrapper<boolean>>(url, data);
  }
}
