import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

import { ModalComponent } from '../../components/modal/modal.component';
import { SignInComponent } from '../../components/sign-in/sign-in.component';

@Component({
  selector: 'xxx-vpn-sign-in-page',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ModalComponent, SignInComponent],
})
export class SignInPage {
  constructor(private readonly router: Router) {
    //
  }

  public closeSignIn() {
    this.router.navigate(['/']);
  }
}
