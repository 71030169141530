import { ICost } from './tariff.interface';

export enum ESourcePayment {
  'appleStore' = 'appleStore',
  'googlePay' = 'googlePay',
  'cloudPayments' = 'cloudPayments',
  'admin' = 'admin',
}

export enum EProductType {
  'one_month_trial' = 'one_month_trial',
  'one_month' = 'one_month',
  'six_months' = 'six_months',
  'one_year' = 'one_year',
}

export const DProductTypes: EProductType[] = [
  EProductType.one_month,
  EProductType.six_months,
  EProductType.one_year,
];

export interface IOrder {
  user_id: string;
  date_start: Date;
  date_end: Date;
  source_payment: ESourcePayment;
  product_type: EProductType;
}

export interface RecurringChargeOptions {
  publicId: string;
  description: string;
  amount: number;
  currency: ICost;
  invoiceId?: string; // номер заказа
  accountId: string; // Идентификатор плательщика
  data?: {
    // Параметры регулярного платежа
    CloudPayments: {
      customerReceipt: CustomerReceipt;
      recurrent: RecurringCharge;
    };
    [key: string]: any;
  };
}

export interface RecurringCharge {
  interval: 'Day' | 'Week' | 'Month';
  period: number; // период в комбинации 1 Month раз в месяц, 2 Week раз в две недели
  /**
   * Дата и время первого регулярного платежа по умолчанию запуск произойдёт
   * через указанный интервал и период например через месяц
   **/
  startDate?: Date;
  customerReceipt?: CustomerReceipt; // Данные для форматирования онлайн чека в регулярных платежах
}

export interface CustomerReceiptItem {
  /**
   * Наименование товара
   * */
  label: string;

  /**
   * Количество товара
   * */
  quantity: number;

  /**
   * Цена за единицу товара
   * */
  price: number;

  /**
   * Ставка НДС
   * */
  vat: number;

  /**
   * Тип оплаты
   * */
  method: number;

  /**
   * Признак способа расчета
   * */
  object: number;
}

export interface CustomerReceipt {
  /**
   * Товарные позиции
   * */
  Items: CustomerReceiptItem[];

  /**
   * Система налогообложения
   * */
  taxationSystem?:
    | 'osn'
    | 'usn_income'
    | 'usn_income_outcome'
    | 'envd'
    | 'esn'
    | 'patent';

  /**
   * Email покупателя
   * */
  email?: string;

  /**
   * Телефон покупателя
   * */
  phone?: string;

  /**
   * Чек является бланком строгой отчетности
   */
  isBso?: boolean;

  amounts?: {
    electronic: number;
    advancePayment: string;
    credit: string;
    provision: string;
  };
}
