export interface IConnectionStats {
  id: string;
  user_id: string;
  client_ip: string;
  device_id: string;
  endpoint: string;
  statusCode: number;
  startTime: number;
  endTime: number;
}
