import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from './../environments/environment';

@Injectable()
export class ServerInterceptor implements HttpInterceptor {
  constructor() {
    //
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const newRequest = request.url.startsWith('/assets')
      ? request
      : request.clone({
          url: `${environment.SERVER_API}${request.url}`,
        });
    return next.handle(newRequest);
  }
}
