import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import { RouterLink } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { HeaderComponent } from '../../components/header/header.component';

@Component({
  selector: 'xxx-vpn-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink, NgIf, HeaderComponent],
})
export class PrivacyComponent {
  translate = inject(TranslateService);
  currentLanguage = signal<string>(
    this.translate.currentLang || this.translate.defaultLang,
  );
}
