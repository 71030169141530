import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ForgotComponent } from '../../components/forgot/forgot.component';
import { ModalComponent } from '../../components/modal/modal.component';

@Component({
  selector: 'xxx-vpn-forgot-password',
  standalone: true,
  imports: [CommonModule, ModalComponent, ForgotComponent],
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  constructor(private readonly router: Router) {
    //
  }

  public closeSignIn() {
    this.router.navigate(['/']);
  }
}
