<xxx-vpn-header></xxx-vpn-header>

<div class="terms">
  <!--Английский язык-->
  <ng-container *ngIf="currentLanguage() === 'en'">
    <div class="terms-text">
      <h1>TERMS OF USE</h1>
      <h2>AGREEMENT TO TERMS</h2>
      <h3>These Terms of Use</h3>
      <p>
        constitute a legally binding agreement made between you, whether
        personally or on behalf of an entity (“you”) and Global-77 LLC
        ("Company", “we”, “us”, or “our”), concerning your access to and use of
        our website as well as any other media form, media channel, mobile
        website or mobile application related, linked, or otherwise connected
        thereto (collectively, the “Site”). You agree that by accessing the
        Site, you have read, understood, and agreed to be bound by all of these
        Terms of Use.
        <strong
          >IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE
          EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE
          IMMEDIATELY.</strong
        >
      </p>

      <h3>Supplemental terms and conditions</h3>
      <p>
        or documents that may be posted on the Site from time to time are hereby
        expressly incorporated herein by reference. We reserve the right, in our
        sole discretion, to make changes or modifications to these Terms of Use
        at any time and for any reason. We will alert you about any changes by
        updating the “Last updated” date of these Terms of Use, and you waive
        any right to receive specific notice of each such change. It is your
        responsibility to periodically review these Terms of Use to stay
        informed of updates. You will be subject to, and will be deemed to have
        been made aware of and to have accepted, the changes in any revised
        Terms of Use by your continued use of the Site after the date such
        revised Terms of Use are posted.
      </p>
      <p>
        The information provided on the Site is not intended for distribution to
        or use by any person or entity in any jurisdiction or country where such
        distribution or use would be contrary to law or regulation or which
        would subject us to any registration requirement within such
        jurisdiction or country. Accordingly, those persons who choose to access
        the Site from other locations do so on their own initiative and are
        solely responsible for compliance with local laws, if and to the extent
        local laws are applicable.
      </p>
      <h3>INTELLECTUAL PROPERTY RIGHTS</h3>
      <p>
        Unless otherwise indicated, the Site is our proprietary property and all
        source code, databases, functionality, software, website designs, audio,
        video, text, photographs, and graphics on the Site (collectively, the
        “Content”) and the trademarks, service marks, and logos contained
        therein (the “Marks”) are owned or controlled by us or licensed to us,
        and are protected by copyright and trademark laws and various other
        intellectual property rights and unfair competition laws of the United
        States, international copyright laws, and international conventions. The
        Content and the Marks are provided on the Site “AS IS” for your
        information and personal use only.
      </p>
      <p>
        Except as expressly provided in these Terms of Use, no part of the Site
        and no Content or Marks may be copied, reproduced, aggregated,
        republished, uploaded, posted, publicly displayed, encoded, translated,
        transmitted, distributed, sold, licensed, or otherwise exploited for any
        commercial purpose whatsoever, without our express prior written
        permission. Provided that you are eligible to use the Site, you are
        granted a limited license to access and use the Site and to download or
        print a copy of any portion of the Content to which you have properly
        gained access solely for your personal, non-commercial use. We reserve
        all rights not expressly granted to you in and to the Site, the Content
        and the Marks.
      </p>

      <h3>USER REPRESENTATIONS</h3>
      <p>By using the Site, you represent and warrant that:</p>
      <ul>
        <li>
          (1) you have the legal capacity and you agree to comply with these
          Terms of Use;
        </li>
        <li>
          (2) you are not a minor in the jurisdiction in which you reside;
        </li>
        <li>
          (3) you will not access the Site through automated or non-human means,
          whether through a bot, script, or otherwise;
        </li>
        <li>
          (4) you will not use the Site for any illegal or unauthorized purpose;
          and
        </li>
        <li>
          (5) your use of the Site will not violate any applicable law or
          regulation.
        </li>
      </ul>
      <p>
        If you provide any information that is untrue, inaccurate, not current,
        or incomplete, we have the right to suspend or terminate your account
        and refuse any and all current or future use of the Site (or any portion
        thereof).
      </p>

      <h3>PROHIBITED ACTIVITIES</h3>
      <p>
        You may not access or use the Site for any purpose other than that for
        which we make the Site available. The Site may not be used in connection
        with any commercial endeavors except those that are specifically
        endorsed or approved by us. As a user of the Site, you agree not to:
      </p>
      <ul>
        <li>
          • Violate any local, state, provincial, national, or other law or
          regulation, or any order of a court;
        </li>
        <li>
          • Infringe the rights of any person or entity, including without
          limitation, their intellectual property, privacy, publicity or
          contractual rights;
        </li>
        <li>
          • Interfere with or damage our Site, including, without limitation,
          through the use of viruses, cancel bots, Trojan horses, harmful code,
          flood pings, denial-of-service attacks, packet or IP spoofing, forged
          routing or electronic mail address information or similar methods or
          technology;
        </li>
        <li>
          • Use automated scripts to collect information or otherwise interact
          with the Site
        </li>
      </ul>

      <h3>USER GENERATED CONTRIBUTIONS</h3>
      <p>
        The Site does not offer users to submit or post content. We may provide
        you with the opportunity to create, submit, post, display, transmit,
        perform, publish, distribute, or broadcast content and materials to us
        or on the Site, including but not limited to text, writings, video,
        audio, photographs, graphics, comments, suggestions, or personal
        information or other material (collectively, "Contributions").
      </p>

      <h3>
        Contributions may be viewable by other users of the Site and through
        third-party websites.
      </h3>
      <p>
        As such, any Contributions you transmit may be treated in accordance
        with the Site Privacy Policy. When you create or make available any
        Contributions, you thereby represent and warrant that:
      </p>
      <ol>
        <li>
          The creation, distribution, transmission, public display, or
          performance, and the accessing, downloading, or copying of your
          Contributions do not and will not infringe the proprietary rights,
          including but not limited to the copyright, patent, trademark, trade
          secret, or moral rights of any third party.
        </li>
        <li>
          You are the creator and owner of or have the necessary licenses,
          rights, consents, releases, and permissions to use and to authorize
          us, the Site, and other users of the Site to use your Contributions in
          any manner contemplated by the Site and these Terms of Use.
        </li>
        <li>
          You have the written consent, release, and/or permission of each and
          every identifiable individual person in your Contributions to use the
          name or likeness of each and every such identifiable individual person
          to enable inclusion and use of your Contributions in any manner
          contemplated by the Site and these Terms of Use.
        </li>
        <li>Your Contributions are not false, inaccurate, or misleading.</li>
        <li>
          Your Contributions are not unsolicited or unauthorized advertising,
          promotional materials, pyramid schemes, chain letters, spam, mass
          mailings, or other forms of solicitation.
        </li>
        <li>
          Your Contributions are not obscene, lewd, lascivious, filthy, violent,
          harassing, libelous, slanderous, or otherwise objectionable (as
          determined by us).
        </li>
        <li>
          Your Contributions do not ridicule, mock, disparage, intimidate, or
          abuse anyone.
        </li>
        <li>
          Your Contributions do not advocate the violent overthrow of any
          government or incite, encourage, or threaten physical harm against
          another.
        </li>
        <li>
          Your Contributions do not violate any applicable law, regulation, or
          rule.
        </li>
        <li>
          Your Contributions do not violate the privacy or publicity rights of
          any third party.
        </li>
        <li>
          Your Contributions do not contain any material that solicits personal
          information from anyone under the age of 18 or exploits people under
          the age of 18 in a sexual or violent manner.
        </li>
        <li>
          Your Contributions do not violate any applicable law concerning child
          pornography, or otherwise intended to protect the health or well-being
          of minors;
        </li>
        <li>
          Your Contributions do not include any offensive comments that are
          connected to race, national origin, gender, sexual preference, or
          physical handicap.
        </li>
        <li>
          Your Contributions do not otherwise violate, or link to material that
          violates, any provision of these Terms of Use, or any applicable law
          or regulation.
        </li>
      </ol>

      <h3>
        Any use of the Site in violation of the foregoing violates these Terms
        of Use and may result in, among other things, termination or suspension
        of your rights to use the Site.
      </h3>
      <p><strong>CONTRIBUTION LICENSE</strong></p>
      <p>
        You and the Site agree that we may access, store, process, and use any
        information and personal data that you provide following the terms of
        the Privacy Policy and your choices (including settings). By submitting
        suggestions or other feedback regarding the Site, you agree that we can
        use and share such feedback for any purpose without compensation to you.
        We do not assert any ownership over your Contributions. You retain full
        ownership of all of your Contributions and any intellectual property
        rights or other proprietary rights associated with your Contributions.
        We are not liable for any statements or representations in your
        Contributions provided by you in any area on the Site. You are solely
        responsible for your Contributions to the Site and you expressly agree
        to exonerate us from any and all responsibility and to refrain from any
        legal action against us regarding your Contributions.
      </p>
      <p><strong>SUBMISSIONS</strong></p>
      <p>
        You acknowledge and agree that any questions, comments, suggestions,
        ideas, feedback, or other information regarding the Site ("Submissions")
        provided by you to us are non-confidential and shall become our sole
        property. We shall own exclusive rights, including all intellectual
        property rights, and shall be entitled to the unrestricted use and
        dissemination of these Submissions for any lawful purpose, commercial or
        otherwise, without acknowledgment or compensation to you. You hereby
        waive all moral rights to any such Submissions, and you hereby warrant
        that any such Submissions are original with you or that you have the
        right to submit such Submissions. You agree there shall be no recourse
        against us for any alleged or actual infringement or misappropriation of
        any proprietary right in your Submissions.
      </p>

      <p><strong>SITE MANAGEMENT</strong></p>
      <p>We reserve the right, but not the obligation, to:</p>
      <ul>
        <li>monitor the Site for violations of these Terms of Use;</li>
        <li>
          take appropriate legal action against anyone who, in our sole
          discretion, violates the law or these Terms of Use, including without
          limitation, reporting such user to law enforcement authorities;
        </li>
        <li>
          in our sole discretion and without limitation, refuse, restrict access
          to, limit the availability of, or disable (to the extent
          technologically feasible) any of your Contributions or any portion
          thereof;
        </li>
        <li>
          in our sole discretion and without limitation, notice, or liability,
          to remove from the Site or otherwise disable all files and content
          that are excessive in size or are in any way burdensome to our
          systems; and
        </li>
        <li>
          otherwise manage the Site in a manner designed to protect our rights
          and property and to facilitate the proper functioning of the Site.
        </li>
      </ul>
      <p><strong>TERM AND TERMINATION</strong></p>
      <p>
        These Terms of Use shall remain in full force and effect while you use
        the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE
        RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
        LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING
        CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON,
        INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY,
        OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR
        REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR
        DELETE ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT
        WARNING, IN OUR SOLE DISCRETION.
      </p>

      <p>
        If we terminate or suspend your account for any reason, you are
        prohibited from registering and creating a new account under your name,
        a fake or borrowed name, or the name of any third party, even if you may
        be acting on behalf of the third party. In addition to terminating or
        suspending your account, we reserve the right to take appropriate legal
        action, including without limitation pursuing civil, criminal, and
        injunctive redress.
      </p>
      <p><strong>MODIFICATIONS AND INTERRUPTIONS</strong></p>
      <p>
        We reserve the right to change, modify, or remove the contents of the
        Site at any time or for any reason at our sole discretion without
        notice. However, we have no obligation to update any information on our
        Site. We also reserve the right to modify or discontinue all or part of
        the Site without notice at any time.
      </p>
      <p>
        We will not be liable to you or any third party for any modification,
        price change, suspension, or discontinuance of the Site. We cannot
        guarantee the Site will be available at all times. We may experience
        hardware, software, or other problems or need to perform maintenance
        related to the Site, resulting in interruptions, delays, or errors.
      </p>
      <p>
        We reserve the right to change, revise, update, suspend, discontinue, or
        otherwise modify the Site at any time or for any reason without notice
        to you. You agree that we have no liability whatsoever for any loss,
        damage, or inconvenience caused by your inability to access or use the
        Site during any downtime or discontinuance of the Site. Nothing in these
        Terms of Use will be construed to obligate us to maintain and support
        the Site or to supply any corrections, updates, or releases in
        connection therewith.
      </p>
      <p><strong>GOVERNING LAW</strong></p>
      <p>
        These Terms shall be governed by and defined following the laws of
        Armenia, excluding the conflicts of law rules thereof.
      </p>
      <p><strong>CORRECTIONS</strong></p>
      <p>
        There may be information on the Site that contains typographical errors,
        inaccuracies, or omissions, including descriptions, pricing,
        availability, and various other information. We reserve the right to
        correct any errors, inaccuracies, or omissions and to change or update
        the information on the Site at any time, without prior notice.
      </p>

      <p><strong>DISCLAIMER</strong></p>
      <p>
        THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT
        YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK.
      </p>
      <p>
        TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
        EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF,
        INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
      </p>
      <p>
        WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
        COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED
        TO THE SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY:
      </p>
      <ul>
        <li>(1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS,</li>
        <li>
          (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
          RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE,
        </li>
        <li>
          (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY
          AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
          THEREIN,
        </li>
        <li>
          (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE,
        </li>
        <li>
          (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
          TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR
        </li>
        <li>
          (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY
          LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
          CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE.
        </li>
      </ul>
      <p>
        WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
        PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE
        SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION
        FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY
        TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN
        YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
      </p>
      <p>
        AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN
        ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION
        WHERE APPROPRIATE.
      </p>
      <p><strong>LIMITATIONS OF LIABILITY</strong></p>
      <p>
        IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO
        YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
        EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
        PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR
        USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
        DAMAGES.
      </p>
      <p>
        NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY
        TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE
        ACTION, WILL AT ALL TIMES BE LIMITED TO THE LESSER OF THE AMOUNT PAID,
        IF ANY, BY YOU TO US.
      </p>
      <p>
        CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON
        IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF
        THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR
        LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
      </p>

      <h3>INDEMNIFICATION</h3>
      <p>
        You agree to defend, indemnify, and hold us harmless, including our
        subsidiaries, affiliates, and all of our respective officers, agents,
        partners, and employees, from and against any loss, damage, liability,
        claim, or demand, including reasonable attorneys’ fees and expenses,
        made by any third party due to or arising out of:
      </p>
      <ol>
        <li>use of the Site;</li>
        <li>breach of these Terms of Use;</li>
        <li>
          any breach of your representations and warranties set forth in these
          Terms of Use;
        </li>
        <li>
          your violation of the rights of a third party, including but not
          limited to intellectual property rights; or
        </li>
        <li>
          any overt harmful act toward any other user of the Site with whom you
          connected via the Site.
        </li>
      </ol>
      <p>
        Notwithstanding the foregoing, we reserve the right, at your expense, to
        assume the exclusive defense and control of any matter for which you are
        required to indemnify us, and you agree to cooperate, at your expense,
        with our defense of such claims. We will use reasonable efforts to
        notify you of any such claim, action, or proceeding which is subject to
        this indemnification upon becoming aware of it.
      </p>

      <h3>USER DATA</h3>
      <p>
        We will maintain certain data that you transmit to the Site for the
        purpose of managing the performance of the Site, as well as data
        relating to your use of the Site. Although we perform regular routine
        backups of data, you are solely responsible for all data that you
        transmit or that relates to any activity you have undertaken using the
        Site. You agree that we shall have no liability to you for any loss or
        corruption of any such data, and you hereby waive any right of action
        against us arising from any such loss or corruption of such data.
      </p>
      <h3>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h3>
      <p>
        Visiting the Site, sending us emails, and completing online forms
        constitute electronic communications. You consent to receive electronic
        communications, and you agree that all agreements, notices, disclosures,
        and other communications we provide to you electronically, via email and
        on the Site, satisfy any legal requirement that such communication be in
        writing.
      </p>
      <p>
        YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS,
        AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND
        RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE.
        You hereby waive any rights or requirements under any statutes,
        regulations, rules, ordinances, or other laws in any jurisdiction which
        require an original signature or delivery or retention of non-electronic
        records, or to payments or the granting of credits by any means other
        than electronic means.
      </p>

      <h3>MISCELLANEOUS</h3>
      <p>
        These Terms of Use and any policies or operating rules posted by us on
        the Site or in respect to the Site constitute the entire agreement and
        understanding between you and us. Our failure to exercise or enforce any
        right or provision of these Terms of Use shall not operate as a waiver
        of such right or provision. These Terms of Use operate to the fullest
        extent permissible by law. We may assign any or all of our rights and
        obligations to others at any time. We shall not be responsible or liable
        for any loss, damage, delay, or failure to act caused by any cause
        beyond our reasonable control. If any provision or part of a provision
        of these Terms of Use is determined to be unlawful, void, or
        unenforceable, that provision or part of the provision is deemed
        severable from these Terms of Use and does not affect the validity and
        enforceability of any remaining provisions. There is no joint venture,
        partnership, employment or agency relationship created between you and
        us as a result of these Terms of Use or use of the Site. You agree that
        these Terms of Use will not be construed against us by virtue of having
        drafted them. You hereby waive any and all defenses you may have based
        on the electronic form of these Terms of Use and the lack of signing by
        the parties hereto to execute these Terms of Use.
      </p>

      <h3>CONTACT US</h3>
      <p>
        In order to resolve a complaint regarding the Site or to receive further
        information regarding use of the Site, please contact us at:
      </p>
      <address>
        Email:
        <a href="mailto:info@vivpnxxx.com" target="_blank">info&#64;vivpnxxx.com</a>
      </address>
    </div>
  </ng-container>
  <ng-container *ngIf="currentLanguage() === 'ru'">
    <div class="terms-text">
      <p>
        <strong>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ СОГЛАСИЕ С УСЛОВИЯМИ</strong>&nbsp;
      </p>
      <p>
        Данные Условия использования являются юридически обязательным
        соглашением, заключенным между вами, в качестве физического лица или от
        имени организации («вы»), и ИП Иванов Олег Владимирович («Компания»,
        «мы», «нас» или «наша»), относительно вашего доступа к нашему веб-сайту
        и использования его, а также любых других форматов медиа, каналов медиа,
        мобильного веб-сайта или мобильного приложения, связанных, связанных или
        иным образом связанных с ним (вместе именуемые «Сайт»). Вы соглашаетесь
        с тем, что, получая доступ к Сайту, вы прочитали, поняли и согласились
        быть связанными всеми этими Условиями использования. ЕСЛИ ВЫ НЕ СОГЛАСНЫ
        С ВСЕМИ ЭТИМИ УСЛОВИЯМИ ИСПОЛЬЗОВАНИЯ, ВЫ ИСКЛЮЧИТЕЛЬНО ЗАПРЕЩЕНЫ ОТ
        ИСПОЛЬЗОВАНИЯ САЙТА И ВЫ ДОЛЖНЫ НЕМЕДЛЕННО ПРЕКРАТИТЬ ЕГО ИСПОЛЬЗОВАНИЕ.
        Дополнительные условия или документы, которые могут быть размещены на
        Сайте время от времени, явно включены в настоящий документ. Мы оставляем
        за собой право по своему усмотрению вносить изменения или модификации в
        эти Условия использования в любое время и по любой причине. Мы уведомим
        вас обо всех изменениях, обновив дату «Последнего обновления» этих
        Условий использования, и вы отказываетесь от права получать конкретное
        уведомление об каждом таком изменении. Ваша обязанность периодически
        просматривать эти Условия использования для получения информации о
        обновлениях. После размещения пересмотренных Условий использования ваше
        дальнейшее использование Сайта будет означать, что вы подчиняетесь и
        ознакомлены с изменениями в любых пересмотренных Условиях использования.
        Информация, предоставленная на Сайте, не предназначена для
        распространения или использования любым лицом или организацией в любой
        юрисдикции или стране, где такое распространение или использование
        противоречило бы закону или регулированию или могло подвергнуть нас
        требованию о регистрации в такой юрисдикции или стране. Следовательно,
        те лица, которые выбирают доступ к Сайту из других местоположений,
        делают это по своей собственной инициативе и полностью несут
        ответственность за соблюдение местных законов, если и в той мере, в
        которой местные законы являются применимыми.&nbsp;
      </p>
      <p>
        <strong>ПРАВА ИНТЕЛЛЕКТУАЛЬНОЙ СОБСТВЕННОСТИ&nbsp;</strong>
      </p>
      <p>
        Если не указано иное, Сайт является нашей собственностью, и весь
        исходный код, базы данных, функциональность, программное обеспечение,
        дизайн веб-сайта, аудио, видео, текст, фотографии и графика на Сайте
        (вместе именуемые «Контент») и товарные знаки, служебные знаки и
        логотипы, содержащиеся в них («Марки»), принадлежат нам или
        контролируются нами, за исключением случаев, когда они находятся под
        лицензией у нас. Они защищены законами об авторском праве и товарных
        знаках, различными другими правами интеллектуальной собственности и
        законами о недобросовестной конкуренции Соединенных Штатов,
        международными законами об авторском праве и международными
        соглашениями. Контент и Марки предоставляются на Сайте «КАК ЕСТЬ» только
        для вашей информации и личного использования. За исключением случаев,
        явно предусмотренных в настоящих Условиях использования, ни одна часть
        Сайта, ни Контент, ни Марки не могут быть скопированы, воспроизведены,
        агрегированы, перепечатаны, загружены, размещены, публично отображены,
        закодированы, переведены, переданы, распространены, проданы,
        лицензированы или использованы иным образом в коммерческих целях без
        нашего явного письменного разрешения. При условии, что вы имеете право
        использовать Сайт, вам предоставляется ограниченная лицензия на доступ и
        использование Сайта, а также загрузку или печать копии любой части
        Контента, к которому вы имеете право на доступ, исключительно для вашего
        личного некоммерческого использования. Мы оставляем за собой все права,
        не являющиеся явно предоставленными вам в отношении Сайта, Контента и
        Марок.&nbsp;
      </p>
      <p>
        <strong>ПРЕДСТАВЛЕНИЯ ПОЛЬЗОВАТЕЛЯ&nbsp;</strong>
      </p>
      <p>
        Используя Сайт, вы заявляете и гарантируете, что: (1) у вас есть
        правоспособность и вы соглашаетесь соблюдать настоящие Условия
        использования; (2) вы не являетесь несовершеннолетним в юрисдикции
        вашего проживания; (3) вы не будете получать доступ к Сайту с
        использованием автоматизированных или нечеловеческих средств, будь то
        боты, скрипты или иные средства; (4) вы не будете использовать Сайт для
        каких-либо незаконных или несанкционированных целей; и (5) ваше
        использование Сайта не будет нарушать никакое применимое
        законодательство или регулирование. Если вы предоставите недостоверную,
        неточную, устаревшую или неполную информацию, у нас будет право
        приостановить или прекратить вашу учетную запись и отказать в любом
        текущем или будущем использовании Сайта (или его части).&nbsp;
      </p>
      <p>
        <strong>ЗАПРЕЩЕННАЯ ДЕЯТЕЛЬНОСТЬ&nbsp;</strong>
      </p>
      <p>
        Вы не можете получать доступ к Сайту или использовать его для каких-либо
        целей, кроме тех, для которых мы предоставляем доступ к Сайту. Сайт не
        может использоваться в связи с какими-либо коммерческими предприятиями,
        кроме тех, которые явно одобрены или одобрены нами. Как пользователь
        Сайта, вы соглашаетесь не:&nbsp;
      </p>
      <p>
        • Нарушать местное, региональное, национальное или другое
        законодательство или нормы или решения суда;&nbsp;
      </p>
      <p>
        • Нарушать права любого лица или организации, включая, помимо прочего,
        его интеллектуальную собственность, конфиденциальность, публичность или
        договорные права;&nbsp;
      </p>
      <p>
        • Повредить или нанести ущерб нашему Сайту, включая, помимо прочего,
        использование вирусов, отмены ботов, троянов, вредоносного кода,
        флуд-пингов, атак отказа в обслуживании, подделку IP-адресов или адресов
        электронной почты или подобных методов или технологий;
      </p>
      <p>
        • Использовать автоматизированные сценарии для сбора информации или
        взаимодействия с Сайтом.&nbsp;
      </p>
      <p>
        <strong>ПОЛЬЗОВАТЕЛЬСКИЕ ВКЛАДЫ&nbsp;</strong>
      </p>
      <p>
        Сайт не предлагает пользователям представлять или публиковать контент.
        Мы можем предоставить вам возможность создавать, представлять,
        публиковать, отображать, передавать, исполнять, публиковать,
        распространять или транслировать контент и материалы на нас или на
        Сайте, включая, но не ограничиваясь, текстом, записями, видео, аудио,
        фотографиями, графикой, комментариями, предложениями или личной
        информацией или другим материалом (вместе именуемые «Вклады»). Вклады
        могут быть видимы другими пользователями Сайта и через веб-сайты
        сторонних лиц. Таким образом, любые ваши предоставляемые Вклады могут
        быть обрабатываться в соответствии с Политикой конфиденциальности Сайта.
        Когда вы создаете или делаете доступными какиелибо Вклады, вы тем самым
        заявляете и гарантируете, что:&nbsp;
      </p>
      <p>
        1. Создание, распространение, передача, публичное отображение или
        исполнение и доступ, загрузка или копирование ваших Вкладов не нарушает
        права собственности, включая, помимо прочего, авторские, патентные,
        товарные знаки, коммерческие секреты или моральные права любого третьего
        лица.&nbsp;
      </p>
      <p>
        2. Вы являетесь создателем и владельцем или имеете необходимые лицензии,
        права, согласия, релизы и разрешения на использование и предоставление
        нам, Сайту и другим пользователям Сайта ваших Вкладов в любой манере,
        предусмотренной Сайтом и настоящими Условиями использования.&nbsp;
      </p>
      <p>
        3. У вас есть письменное согласие, релиз и/или разрешение каждого
        идентифицируемого физического лица в ваших Вкладах использовать имя или
        образ каждого такого идентифицируемого физического лица для возможности
        включения и использования ваших Вкладов в любой манере, предусмотренной
        Сайтом и настоящими Условиями использования.&nbsp;
      </p>
      <p>
        4. Ваши Вклады не являются ложными, неточными или вводящими в
        заблуждение.&nbsp;
      </p>
      <p>
        5. Ваши Вклады не являются непрошеной или несанкционированной рекламой,
        промоматериалами, пирамидальными схемами, цепными письмами, спамом,
        массовыми рассылками или другими формами привлечения.
      </p>
      <p>
        6. Ваши Вклады не содержат непристойных, развратных, пошлых, грубых,
        жестоких, домогательных, клеветнических, наклеветнических или иных
        оскорбительных материалов (по нашему усмотрению).&nbsp;
      </p>
      <p>
        7. Ваши Вклады не насмешничают, не высмеивают, не осуждают, не
        запугивают и не злоупотребляют над кем-либо.&nbsp;
      </p>
      <p>
        8. Ваши Вклады не пропагандируют насильственное свержение любого
        правительства или не подстрекают, не поощряют и не угрожают физическим
        причинением вреда другому человеку.&nbsp;
      </p>
      <p>
        9. Ваши Вклады не нарушают применимое законодательство, нормативные акты
        или правила.&nbsp;
      </p>
      <p>
        10. Ваши Вклады не нарушают права конфиденциальности или публичности
        какого-либо третьего лица.&nbsp;
      </p>
      <p>
        11. Ваши Вклады не содержат материалов, направленных на получение
        персональной информации от лиц младше 18 лет или на использование лиц
        младше 18 лет в сексуальной или жестокой манере.&nbsp;
      </p>
      <p>
        12. Ваши Вклады не нарушают применимые законы, касающиеся детской
        порнографии или иных мер, направленных на защиту здоровья или
        благополучия несовершеннолетних;&nbsp;
      </p>
      <p>
        13. Ваши Вклады не содержат оскорбительных комментариев, связанных с
        расой, национальностью, полом, сексуальными предпочтениями или
        физическими недостатками.&nbsp;
      </p>
      <p>
        14. Ваши Вклады не нарушают и не связаны с материалами, нарушающими
        любые положения настоящих Условий использования, или применимое
        законодательство или нормативные акты. Любое использование Сайта,
        нарушающее вышесказанное, нарушает настоящие Условия использования и
        может привести к прекращению или приостановке вiаших прав на
        использование Сайта.&nbsp;
      </p>
      <p>
        <strong>ЛИЦЕНЗИЯ НА ВКЛАДЫ</strong>
      </p>
      <p>
        &nbsp;Вы и Сайт соглашаетесь с тем, что мы можем получать доступ,
        хранить, обрабатывать и использовать любую информацию и персональные
        данные, которые вы предоставляете в соответствии с положениями Политики
        конфиденциальности и вашими выборами (включая настройки). Предоставляя
        предложения или другую информацию о Сайте, вы соглашаетесь с тем, что мы
        можем использовать и распространять такую информацию для любых целей без
        выплаты вам какой-либо компенсации. Мы не заявляем о собственности на
        ваши Вклады. Вы полностью сохраняете все права собственности на все свои
        Вклады и любые права интеллектуальной собственности или иные
        проприетарные права, связанные с вашими Вкладами. Мы не несем
        ответственности за какие-либо заявления или представления ваших Вкладов,
        предоставленных вами на Сайте. Вы несете полную ответственность за свои
        Вклады на Сайте и явно соглашаетесь освободить нас от любой
        ответственности и воздержаться от любых правовых действий против нас в
        отношении ваших Вкладов.&nbsp;
      </p>
      <p>
        <strong>ПРЕДОСТАВЛЕНИЕ МАТЕРИАЛОВ&nbsp;</strong>
      </p>
      <p>
        Вы признаете и соглашаетесь с тем, что любые вопросы, комментарии,
        предложения, идеи, отзывы или другая информация, касающаяся Сайта
        ("Материалы"), предоставленные вами нам, не являются конфиденциальными и
        становятся нашей единоличной собственностью. Мы будем обладать
        исключительными правами, включая все права интеллектуальной
        собственности, и будем иметь право на неограниченное использование и
        распространение этих Материалов в любых законных целях, коммерческих или
        иных, без указания или выплаты вам какой-либо компенсации. Вы также
        отказываетесь от любого претензий или права на подобную компенсацию в
        отношении использования Материалов, предоставленных вами нам. Мы не
        несем ответственности за проверку или оценку Материалов и не гарантируем
        точность, полноту, своевременность или полезность Материалов. Мы не
        обязуемся поддерживать конфиденциальность или реагировать на любые
        Материалы.&nbsp;
      </p>
      <p>
        <strong>УПРАВЛЕНИЕ САЙТОМ&nbsp;</strong>
      </p>
      <p>
        Мы оставляем за собой право, но не обязанность: (1) контролировать Сайт
        на предмет нарушений настоящих Условий использования; (2) предпринимать
        соответствующие правовые меры против любого лица, на наше усмотрение
        нарушающего закон или настоящие Условия использования, включая, но не
        ограничиваясь, сообщение о таком пользователе правоохранительным
        органам; (3) на наше усмотрение и без ограничений, отказывать,
        ограничивать доступ, ограничивать доступность или отключать (в
        технических пределах) ваши Вклады или их часть; (4) на наше усмотрение и
        без ограничений, уведомление или ответственность, удалять с Сайта или
        иным образом отключать все файлы и контент, которые являются чрезмерными
        по размеру или каким-либо образом нагружают наши системы; и (5) иначе
        управлять Сайтом таким образом, чтобы защитить наши права и
        собственность, а также обеспечить правильное функционирование
        Сайта.&nbsp;
      </p>
      <p>
        <strong>УСЛОВИЯ И ПРЕКРАЩЕНИЕ&nbsp;</strong>
      </p>
      <p>
        Настоящие Правила использования остаются в полной силе и эффекте в
        течение вашего использования сайта.&nbsp;
      </p>
      <p>
        БЕЗ ОГРАНИЧЕНИЯ КАКОГО-ЛИБО ДРУГОГО ПОЛОЖЕНИЯ НАСТОЯЩИХ ПРАВИЛ
        ИСПОЛЬЗОВАНИЯ, МЫ ОСТАВЛЯЕМ ЗА СОБОЙ ПРАВО, ПО СВОЕМУ ВЫКЛЮЧИТЕЛЬНОМУ
        УСМОТРЕНИЮ И БЕЗ УВЕДОМЛЕНИЯ ИЛИ ОТВЕТСТВЕННОСТИ, ОТКАЗАТЬ В ДОСТУПЕ И
        ИСПОЛЬЗОВАНИИ САЙТА (ВКЛЮЧАЯ БЛОКИРОВКУ ОПРЕДЕЛЕННЫХ IP-АДРЕСОВ), ЛЮБОМУ
        ЛИЦУ ПО ЛЮБОЙ ПРИЧИНЕ ИЛИ БЕЗ ПРИЧИНЫ, ВКЛЮЧАЯ, БЕЗ ОГРАНИЧЕНИЯ,
        НАРУШЕНИЕ ЛЮБОГО ЗАЯВЛЕНИЯ, ГАРАНТИИ ИЛИ ДОГОВОРА, СОДЕРЖАЩЕГОСЯ В
        НАСТОЯЩИХ ПРАВИЛАХ ИСПОЛЬЗОВАНИЯ, ИЛИ ЛЮБОГО ДЕЙСТВУЮЩЕГО ЗАКОНА ИЛИ
        РЕГУЛЯТИВНОГО АКТА. МЫ МОЖЕМ ПРЕКРАТИТЬ ВАШЕ ИСПОЛЬЗОВАНИЕ ИЛИ УЧАСТИЕ
        НА САЙТЕ ИЛИ УДАЛИТЬ ЛЮБОЙ КОНТЕНТ ИЛИ ИНФОРМАЦИЮ, КОТОРУЮ ВЫ
        ОПУБЛИКОВАЛИ В ЛЮБОЕ ВРЕМЯ, БЕЗ ПРЕДУПРЕЖДЕНИЯ, ПО НАШЕМУ ЕДИНСТВЕННОМУ
        УСМОТРЕНИЮ.&nbsp;
      </p>
      <p>
        Если мы прекращаем или приостанавливаем вашу учетную запись по любой
        причине, вам запрещено регистрироваться и создавать новую учетную запись
        под вашим именем, фальшивым или заимствованным именем, или именем любого
        третьего лица, даже если вы действуете от имени третьего лица. В
        дополнение к прекращению или приостановке вашей учетной записи, мы
        оставляем за собой право предпринять соответствующие юридические меры,
        включая, без ограничений, гражданский, уголовный и запретительный иск.
        ИЗМЕНЕНИЯ И ПРЕРЫВАНИЕ Мы оставляем за собой право изменять,
        модифицировать или удалить содержимое сайта в любое время или по любой
        причине по нашему единоличному усмотрению без предварительного
        уведомления. Однако мы не обязаны обновлять информацию на нашем сайте.
        Мы также оставляем за собой право изменять или прекращать полностью или
        частично работу сайта без предварительного уведомления в любое время. Мы
        не несем ответственности перед вами или любым третьим лицом за любые
        изменения, изменение цен, приостановку или прекращение работы сайта. Мы
        не можем гарантировать доступность сайта в любое время. У нас могут
        возникнуть проблемы с аппаратным обеспечением, программным обеспечением
        или другими проблемами, а также может потребоваться проведение
        технического обслуживания, связанного с сайтом, что может привести к
        прерываниям, задержкам или ошибкам. Мы оставляем за собой право
        изменять, корректировать, обновлять, приостанавливать, прекращать или
        вносить иные изменения в сайт в любое время или по любой причине без
        предварительного уведомления вам. Вы соглашаетесь с тем, что мы не несем
        никакой ответственности за любые потери, ущерб или неудобства, вызванные
        невозможностью доступа к сайту или его использования во время перерывов
        или прекращения работы сайта. Ничто в настоящих Правилах использования
        не будет толковаться таким образом, чтобы обязывать нас поддерживать и
        поддерживать работу сайта или предоставлять какие-либо исправления,
        обновления или выпуски в связи с ним.&nbsp;
      </p>
      <p><strong>ПРАВОПРИМЕНЯЮЩЕЕ ЗАКОНОДАТЕЛЬСТВО</strong>&nbsp;</p>
      <p>
        Эти Правила подчиняются и толкуются в соответствии с законами Российской
        Федерации, за исключением принципов конфликта законов. ИСПРАВЛЕНИЯ На
        сайте может содержаться информация, содержащая опечатки, неточности или
        упущения, включая описания, цены, наличие и другую информацию. Мы
        оставляем за собой право исправлять любые ошибки, неточности или
        упущения, а также изменять или обновлять информацию на сайте в любое
        время без предварительного уведомления.&nbsp;
      </p>
      <p>
        <strong>ПРЕКРАЩЕНИЕ ИСПОЛЬЗОВАНИЯ</strong>
      </p>
      <p>
        Мы можем, по своему усмотрению, прекратить ваш доступ и использование
        Сайта в любое время и по любой причине, включая нарушение настоящих
        Условий использования. После прекращения вашего доступа к Сайту вы
        должны немедленно прекратить использование Сайта и удалить любые
        материалы, загруженные или полученные с Сайта, включая все копии таких
        материалов.&nbsp;
      </p>
      <p>
        ОТКАЗ ОТ ОТВЕТСТВЕННОСТИ САЙТ И ЕГО КОНТЕНТ ПРЕДОСТАВЛЯЮТСЯ "КАК ЕСТЬ",
        БЕЗ КАКИХ-ЛИБО ГАРАНТИЙ, ВЫРАЖЕННЫХ ИЛИ ПОДРАЗУМЕВАЕМЫХ. В МАКСИМАЛЬНОЙ
        СТЕПЕНИ, РАЗРЕШЕННОЙ ЗАКОНОДАТЕЛЬСТВОМ, МЫ ОТКАЗЫВАЕМСЯ ОТ ВСЕХ
        ГАРАНТИЙ, ВЫРАЖЕННЫХ ИЛИ ПОДРАЗУМЕВАЕМЫХ, ВКЛЮЧАЯ, БЕЗ ОГРАНИЧЕНИЯ,
        ГАРАНТИИ КАЧЕСТВА, ПРИГОДНОСТИ ДЛЯ ОПРЕДЕЛЕННОЙ ЦЕЛИ И НЕНАРУШЕНИЯ. МЫ
        НЕ ГАРАНТИРУЕМ, ЧТО САЙТ БУДЕТ БЕЗОПАСНЫМ, БЕЗОПЕЧНЫМ, НЕПРЕРЫВНЫМ,
        БЕЗОШИБОЧНЫМ, ТОЧНЫМ, НАДЕЖНЫМ ИЛИ БЕЗОПАСНЫМ. ВЫ ИСПОЛЬЗУЕТЕ САЙТ НА
        СВОЙ СТРАХ И РИСК. ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ В МАКСИМАЛЬНОЙ СТЕПЕНИ,
        РАЗРЕШЕННОЙ ЗАКОНОДАТЕЛЬСТВОМ, МЫ, НАШИ ФИЛИАЛЫ, ДОЧЕРНИЕ ПРЕДПРИЯТИЯ,
        АГЕНТЫ, ДИРЕКТОРЫ, СОТРУДНИКИ, ПОСТАВЩИКИ УСЛУГ И ЛИЦЕНЗИАТОРЫ НЕ НЕСЕМ
        ОТВЕТСТВЕННОСТИ ЗА КАКОЙЛИБО ПРЯМОЙ, КОСВЕННЫЙ, ВОЗМЕЗДНЫЙ, СЛУЧАЙНЫЙ,
        НАКАЗУЕМЫЙ, ОСОБЫЙ, ПОСЛЕДОВАТЕЛЬНЫЙ УЩЕРБ, ВКЛЮЧАЯ, БЕЗ ОГРАНИЧЕНИЯ,
        УЩЕРБ ВВИДЕ ПОТЕРИ ПРИБЫЛИ, УЩЕРБ УСТОЙЧИВОМУ БИЗНЕСУ, УЩЕРБ УСТОЙЧИВОМУ
        РЕПУТАЦИИ, УЩЕРБ УСТОЙЧИВОМУ БРЕНДУ, УЩЕРБ ВВИДЕ УПУЩЕННЫХ ВОЗМОЖНОСТЕЙ,
        УЩЕРБ ВВИДЕ ПОТЕРИ ДАННЫХ, УЩЕРБ ВВИДЕ НЕПРАВИЛЬНОГО ИСПОЛЬЗОВАНИЯ ИЛИ
        НЕВОЗМОЖНОСТИ ИСПОЛЬЗОВАНИЯ САЙТА ИЛИ ЛЮБЫХ ПРОДУКТОВ, ПРЕДЛАГАЕМЫХ НА
        САЙТЕ, ВКЛЮЧАЯ, НО НЕ ОГРАНИЧИВАЯСЬ ЭТИМ, ОШИБКИ ИЛИ ОТСУТСТВИЕ
        ТОЧНОСТИ, НЕДОСТАТОЧНОСТЬ, НЕПОЛНОТУ ИЛИ ЗАСТАРЕВШУЮ ИНФОРМАЦИЮ В
        КОНТЕНТЕ, А ТАКЖЕ ВСЕ ПОТЕРИ, ВРЕД ИЗ-ЗА ВИРУСОВ, РАСТВОРОВ ИЛИ ДРУГИХ
        ВРЕДОНОСНЫХ МАТЕРИАЛОВ, В РЕЗУЛЬТАТЕ ВАШЕГО ДОСТУПА К САЙТУ, ИЛИ ВАШЕГО
        ИСПОЛЬЗОВАНИЯ ИЛИ НЕВОЗМОЖНОСТИ ИСПОЛЬЗОВАНИЯ САЙТА, НЕЗАВИСИМО ОТ ТОГО,
        ОСНОВАНА ЛИ ПРЕТЕНЗИЯ НА НАРУШЕНИИ ДОГОВОРА, ГАРАНТИИ, ВИНЫ (ВКЛЮЧАЯ
        НЕБРЕЖНОСТЬ), ПРЕДУПРЕЖДЕНИЕ, ОТВЕТСТВЕННОСТЬ ПО СТРОГОЙ ВИНЕ, ИЛИ ИНОЕ,
        ДАЖЕ В СЛУЧАЕ, ЕСЛИ МЫ БЫЛИ ИЗВЕЩЕНЫ О ВОЗМОЖНОСТИ ТАКОГО УЩЕРБА. ВЫ
        СОГЛАШАЕТЕСЬ, ЧТО ВЫСШАЯ ОТВЕТСТВЕННОСТЬ ЗА ИСПОЛЬЗОВАНИЕ САЙТА И
        КОНТЕНТА НЕСЕТЕ ВЫ. В НЕКОТОРЫХ ЮРИСДИКЦИЯХ ЗАПРЕЩЕН ИЛИ ОГРАНИЧЕН
        ВЫКЛЮЧИТЕЛЬНЫЙ ОТКАЗ ОТ ОТВЕТСТВЕННОСТИ ИЛИ ОГРАНИЧЕНИЕ ВЫШЕУКАЗАННЫХ
        ГАРАНТИЙ ИЛИ УСТАНОВЛЕНИЕ ВЫШЕУКАЗАННОГО УЩЕРБА, ТАК ЧТО ВЫШЕУКАЗАННЫЕ
        ОГРАНИЧЕНИЯ И ОТКАЗЫ МОГУТ НЕ СОВПАДАТЬ С ВАМИ. ВЫ ЗАЯВЛЯЕТЕ И
        ГАРАНТИРУЕТЕ, ЧТО ИСПОЛЬЗОВАНИЕ САЙТА НЕ БУДЕТ НАРУШАТЬ ПРИМЕНИМОГО
        ЗАКОНОДАТЕЛЬСТВА ИЛИ РЕГУЛИРОВАНИЯ. ВЫ СОГЛАШАЕТЕСЬ ЗАЩИЩАТЬ,
        КОМПЕНСИРОВАТЬ И ОСВОБОДИТЬ НАС ОТ ЛЮБЫХ ПРЕТЕНЗИЙ, УБЫТКОВ,
        ОТВЕТСТВЕННОСТИ, РАСХОДОВ ИЛИ РАСХОДОВ (ВКЛЮЧАЯ, БЕЗ ОГРАНИЧЕНИЯ,
        РАЗУМНЫЕ ГОНОРАРЫ АДВОКАТОВ), ВОЗНИКАЮЩИХ ИЛИ СВЯЗАННЫХ С ВАШИМ
        ИСПОЛЬЗОВАНИЕМ САЙТА, ВКЛЮЧАЯ ЛЮБЫЕ ВАШИ ВКЛАДЫ, НАРУШАЮЩИЕ НАСТОЯЩИЕ
        УСЛОВИЯ ИСПОЛЬЗОВАНИЯ ИЛИ ДРУГИЕ ВАШИ ПРАВА.&nbsp;
      </p>
      <p><strong>ВОЗМЕЩЕНИЕ УБЫТКОВ</strong>&nbsp;</p>
      <p>
        Вы соглашаетесь защищать, возмещать ущерб и обеспечивать защиту нас,
        включая наши дочерние компании, аффилированные лица и всех наших
        должностных лиц, агентов, партнеров и сотрудников от любых потерь,
        ущерба, ответственности, претензий или требований, включая разумные
        гонорары адвокатов и расходы, предъявленные любым третьим лицом в связи
        с: (1) использованием сайта; (2) нарушением настоящих Правил
        использования; (3) любым нарушением ваших заявлений и гарантий,
        изложенных в настоящих Правилах использования; (4) нарушением прав
        третьего лица, включая, но не ограничиваясь, правами интеллектуальной
        собственности; или (5) любыми открытыми вредными действиями в отношении
        любого другого пользователя сайта, с которым вы связались через сайт.
        Несмотря на вышеизложенное, мы оставляем за собой право за ваш счет
        принять исключительную защиту и контроль над любым вопросом, по которому
        вы обязаны возместить нам ущерб, и вы соглашаетесь сотрудничать, за ваш
        счет, с нашей защитой по таким претензиям. Мы приложим разумные усилия,
        чтобы уведомить вас о любой такой претензии, иске или процессе, который
        подлежит возмещению, как только узнаем о нем. ПОЛЬЗОВАТЕЛЬСКИЕ ДАННЫЕ Мы
        будем сохранять определенные данные, которые вы передаете на сайт для
        управления функционированием сайта, а также данные, относящиеся к вашему
        использованию сайта. Хотя мы регулярно выполняем резервное копирование
        данных, вы полностью несете ответственность за все данные, которые вы
        передаете или которые относятся к любой деятельности, которую вы
        выполняете с использованием сайта. Вы соглашаетесь, что мы не несем
        ответственности перед вами за любые потери или повреждения таких данных,
        и вы отказываетесь от права предъявить нам иск, возникающий из любых
        таких потерь или повреждений таких данных.
      </p>
      <p>
        <strong>ЭЛЕКТРОННЫЕ КОММУНИКАЦИИ, ТРАНЗАКЦИИ И ПОДПИСИ</strong>
      </p>
      <p>
        Посещение Сайта, отправка нам электронных писем и заполнение онлайн-форм
        являются электронными коммуникациями. Вы соглашаетесь получать
        электронные коммуникации и соглашаетесь, что все соглашения,
        уведомления, раскрытия и другие коммуникации, которые мы предоставляем
        вам электронным путем, по электронной почте и на Сайте, удовлетворяют
        любые законные требования, согласно которым такая коммуникация должна
        быть в письменной форме.&nbsp;
      </p>
      <p>
        ВЫ СОГЛАШАЕТЕСЬ С ИСПОЛЬЗОВАНИЕМ ЭЛЕКТРОННЫХ ПОДПИСЕЙ, ДОГОВОРОВ,
        ЗАКАЗОВ И ДРУГИХ ДОКУМЕНТОВ, А ТАКЖЕ С ЭЛЕКТРОННОЙ ДОСТАВКОЙ
        УВЕДОМЛЕНИЙ, ПОЛИТИК И ДОКУМЕНТОВ О ТРАНЗАКЦИЯХ, ИНИЦИИРОВАННЫХ ИЛИ
        ЗАВЕРШЕННЫХ НАМИ ИЛИ ПОСРЕДСТВОМ САЙТА.&nbsp;
      </p>
      <p>
        Вы отказываетесь от любых прав или требований в соответствии с
        какими-либо законами, правилами, постановлениями или иными
        законодательными актами в любой юрисдикции, которые требуют оригинальной
        подписи или доставки или сохранения неэлектронных документов, а также
        производства платежей или предоставления кредитов посредством иных
        средств, кроме электронных.&nbsp;
      </p>
      <p>
        <strong>РАЗНОЕ&nbsp;</strong>
      </p>
      <p>
        Настоящие Условия использования и любые политики или правила,
        размещенные нами на Сайте или касающиеся Сайта, являются полным
        соглашением и пониманием между вами и нами. Наше неисполнение или
        невыполнение любого права или положения настоящих Условий использования
        не будет рассматриваться как отказ от такого права или положения.
        Настоящие Условия использования применяются в полной мере, допустимой
        законом. Мы вправе передать все или часть наших прав и обязанностей
        третьим лицам в любое время. Мы не несем ответственности за любые
        потери, ущерб, задержку или невыполнение, вызванные любой причиной,
        выходящей за пределы нашего разумного контроля. Если какое-либо
        положение или его часть настоящих Условий использования будет признано
        незаконным, недействительным или не подлежащим исполнению, такое
        положение или его часть будет считаться отделимым от настоящих Условий
        использования и не повлияет на действительность и исполнимость остальных
        положений. Никакое совместное предприятие, партнерство, трудовые
        отношения или агентские отношения между вами и нами не создаются в
        результате данных Условий использования или использования Сайта. Вы
        соглашаетесь с тем, что данные Условия использования не будут
        истолковываться против нас только из-за того, что мы их составили. Вы
        снимаете любую защиту, которая может возникнуть у вас на основании
        электронной формы данных Условий использования и отсутствия подписания
        сторонами данных Условий использования.&nbsp;
      </p>
      <p>
        <strong>СВЯЖИТЕСЬ С НАМИ&nbsp;</strong>
      </p>
      <p>
        Для разрешения жалобы на Сайт или получения дополнительной информации о
        использовании Сайта, пожалуйста, свяжитесь с нами по адресу: Электронная
        почта: info&#64;vivpnxxx.com
      </p>
    </div>
  </ng-container>
</div>
