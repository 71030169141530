export enum ICost {
  RUB = 'rub',
  USD = 'usd',
  EUR = 'eur',
}

export interface ITariff extends Record<ICost, number> {
  id?: string;
  key: string;
  is_trial: boolean;
}
