export type IDOServerStatus = 'active';
export type IDONetworkType = 'public' | 'private';
export interface IImage {
  id: number;
  name: string;
  distribution: string;
  slug: string;
}

export interface IDOServer {
  id: number;
  local_server_id?: string;
  name: string;
  memory: number;
  vcpus: number;
  disk: number;
  locked: boolean;
  status: string;
  isActive: boolean;
  created_at: string;
  image: string | number | IImage;
  size: {
    slug: string;
    memory: number;
    vcpus: number;
    disk: number;
    transfer: number;
    price_monthly: number;
    price_hourly: number;
    description: string;
  };
  size_slug: string;
  networks: {
    v4: Array<{
      ip_address: string;
      netmask: string;
      gateway: string;
      type: IDONetworkType;
    }>;
    v6: Array<{
      ip_address: string;
      netmask: string;
      gateway: string;
      type: IDONetworkType;
    }>;
  };
  region: {
    name: string;
    slug: string;
    available: boolean;
  };
  tags: string[];
  vpc_uuid: string;
}
