import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  signal,
  WritableSignal,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterLink } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { finalize, take } from 'rxjs/operators';

import { ForgotService } from './forgot.service';

@Component({
  selector: 'xxx-vpn-forgot',
  standalone: true,
  imports: [
    CommonModule,
    PaginatorModule,
    ProgressSpinnerModule,
    ReactiveFormsModule,
    RouterLink,
    TranslateModule,
  ],
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss'],
  providers: [ForgotService],
})
export class ForgotComponent {
  form = new FormGroup({
    email: new FormControl<string>('', [Validators.required, Validators.email]),
  });
  message: WritableSignal<string> = signal('');
  loading = false;
  emailSent = false;

  constructor(
    private readonly service: ForgotService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  forgot(): void {
    if (this.form.invalid) {
      return;
    }
    this.loading = true;
    this.cdr.markForCheck();

    const email: string = this.form.value.email || '';

    this.service
      .forgotPassword({ email })
      .pipe(
        finalize(() => {
          this.loading = false;
          this.cdr.markForCheck();
        }),
        take(1),
      )
      .subscribe({
        error: ({ error }) => {
          this.message.set(error.message);
        },
        next: (res) => {
          if (res.status) {
            this.emailSent = true;
            this.cdr.markForCheck();
          }
        },
      });
  }
}
