<div class="sign-up">
  <div class="logo">
    <img src="/assets/images/logo.png" alt="" />
  </div>
  <form
    class="form"
    (ngSubmit)="signUp()"
    [formGroup]="signUpForm"
    autocomplete="off"
  >
    <div class="form-title">Sign Up</div>
    <div class="form-wrapper">
      <div class="form-fields">
        <label class="form-fields-label">E-mail</label>
        <input
          class="form-fields-input"
          type="text"
          placeholder="example@example.com"
          formControlName="email"
        />
      </div>

      <div class="form-fields">
        <label class="form-fields-label">Password</label>
        <input
          class="form-fields-input"
          type="text"
          placeholder="********"
          formControlName="password"
        />
      </div>
    </div>
    <div class="submit">
      <button type="submit" [class.loading]="loading" class="btn-outline">
        <p-progressSpinner
          *ngIf="loading"
          [style]="{ height: '40px', width: '40px' }"
        ></p-progressSpinner>
        Sign Up
      </button>
    </div>
  </form>
  <div class="links">
    <span>Already have an account?</span>
    <a class="links-item" [routerLink]="['/sign-in']">
      <span>Sign in ></span>
    </a>
  </div>
</div>

<p-toast></p-toast>
