import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../environments/environment';

@Injectable()
export class DecryptInterceptor implements HttpInterceptor {
  constructor() {
    //
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const headers = request.headers
      .set('X-Encrypt-Data', environment.decryptKey)
      .set('X-Device-Hash', 'website');
    const newRequest = request.clone({
      headers,
    });
    return next.handle(newRequest);
  }
}
