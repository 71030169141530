import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LangGuard {
  private readonly translate = inject(TranslateService);

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const isRU =
      route.routeConfig?.path?.startsWith('ru') ||
      route.params['lang'] === 'ru';
    this.translate.use(isRU ? 'ru' : 'en');

    return true;
  }
}
