<div class="header">
  <div class="logo">
    <a [routerLink]="['/']">
      <img src="/assets/images/logo.png" alt="" />
    </a>
  </div>

  <div class="btn-outline" [routerLink]="['/sign-in']">
    <img src="/assets/images/user.svg" alt="" />
    <span>{{ 'account.title' | translate }}</span>
  </div>

  <div class="auth-mobile">
    <a [routerLink]="['/sign-in']">
      <img src="/assets/images/user.svg" alt="" />
    </a>
  </div>
</div>
