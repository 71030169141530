import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { IDevice, IOrder, IUser, IWrapper } from '@xxx-vpn/api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  readonly #http = inject(HttpClient);

  /**
   * Информация о пользователе
   */
  loadUserMe() {
    return this.#http.get<IWrapper<IUser>>('/api/user/me');
  }

  /**
   * Активная подписка
   */
  loadUserSubscription(user_id: any) {
    return this.#http.get<
      IWrapper<{
        is_premium: boolean;
        order: IOrder;
      }>
    >(`/api/order/status?user_id_hash=${user_id}`);
  }

  /**
   * Удалить подписку
   */
  removeSubscription() {
    return this.#http.delete<IWrapper<string>>(
      '/api/order/unsubscribe_cloudpayment',
    );
  }

  /**
   * Список устройств
   */
  loadUserDevices() {
    return this.#http.get<IWrapper<IDevice[]>>('/api/device/me');
  }

  /**
   * Удалить устройство
   */
  removeDevice(device_id: string) {
    return this.#http.delete<IWrapper<string>>(`/api/device/me/${device_id}`);
  }
}
