import { ColumnTypeEnum } from '../enums';

export interface IUserTableSetting {
  table_name: string;
  user_id?: string;
  columns: Array<IColumn>;
}

export interface IColumn {
  field: string;
  header: string;
  type: ColumnTypeEnum;
  isHidden: boolean;
}
