import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'xxx-vpn-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf],
})
export class ModalComponent {
  @Output() closed = new EventEmitter();
  @Input() closeButton = true;

  constructor() {
    //
  }

  public close() {
    this.closed.emit();
  }
}
