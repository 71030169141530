import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { Router } from '@angular/router';

import { CloudpaymentService } from './cloudpayment.service';

declare let cp: any;

@Component({
  selector: 'xxx-vpn-cloudpayment',
  templateUrl: './cloudpayment.component.html',
  styleUrls: ['./cloudpayment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class CloudpaymentComponent implements OnInit {
  readonly #cloudpaymentService = inject(CloudpaymentService);
  readonly #router = inject(Router);

  @Input() invoiceId = '';

  ngOnInit(): void {
    this.makePayment();
  }

  async makePayment() {
    const widget = new cp.CloudPayments();
    const options = await this.#cloudpaymentService
      .getOptions(this.invoiceId)
      .toPromise();

    widget.charge(
      {
        ...options,
        skin: 'modern',
        autoClose: 5,
        configuration: {
          common: {
            successRedirectUrl: 'https://vivpnxxx.com/cloudpayment/success', // адреса для перенаправления
            failRedirectUrl: 'https://vivpnxxx.com/cloudpayment/fail', // при оплате по Tinkoff Pay
          },
        },
      },
      (option: any) => {
        this.#router.navigate(['/cloudpayment/success']);
      },
      () => {
        this.#router.navigate(['/cloudpayment/fail']);
      },
    );
  }
}
