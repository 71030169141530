<xxx-vpn-header></xxx-vpn-header>

<div class="policy">
  <!--Английский язык-->
  <ng-container *ngIf="currentLanguage() === 'en'">
    <div class="policy-text">
      <h1 class="policy-title">Privacy Policy</h1>

      Thank you for choosing to be part of our community at Global-77 LLC
      (<strong>“Company”</strong>, <strong>“we”</strong>, <strong>“us”</strong>,
      or <strong>“our”</strong>). We are committed to protecting your personal
      information and your right to privacy. If you have any questions or
      concerns about this privacy policy, or our practices with regards to your
      personal information, please contact us at info&#64;vivpnxxx.com. When you use
      our mobile or PC application, as the case may be (the "App") and more
      generally, use any of our services (the "Services", which include the
      App), we appreciate that you are trusting us with your personal
      information. We take your privacy very seriously. In this privacy policy,
      we seek to explain to you in the clearest way possible what information we
      collect, how we use it and what rights you have in relation to it. We hope
      you take some time to read through it carefully, as it is important. If
      there are any terms in this privacy policy that you do not agree with,
      please discontinue use of our Services immediately. This privacy policy
      applies to all information collected through our Services (which, as
      described above, includes our App), as well as any related services,
      sales, marketing or events.
      <p>
        <strong
          >Please read this privacy policy carefully as it will help you
          understand what we do with the information that we collect.
        </strong>
      </p>

      <h2 class="policy-title">TABLE OF CONTENTS</h2>

      <ul>
        <li>WHAT INFORMATION DO WE COLLECT?</li>
        <li>HOW DO WE USE YOUR INFORMATION?</li>
        <li>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</li>
        <li>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
        <li>WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</li>
        <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
        <li>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
        <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
        <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
        <li>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
        <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
        <li>HOW CAN YOU CONTACT US?</li>
      </ul>

      <h2 class="policy-title">ABOUT THIS NOTICE?</h2>
      <h3 class="policy-title">1. WHAT INFORMATION DO WE COLLECT?</h3>
      <p>In Short: We collect information that you provide to us.</p>
      <p>
        We collect personal information that you voluntarily provide to us when
        you register on the App, express an interest in obtaining information
        about us or our products and Services, when you participate in
        activities on the App or otherwise when you contact us. The personal
        information that we collect depends on the context of your interactions
        with us and the App, the choices you make and the products and features
        you use. The personal information we collect may include the following:
      </p>
      <ul>
        <li>
          <strong>Personal Information Provided by You:</strong> We collect
          email addresses.
        </li>
        <li>
          <strong>Payment Data:</strong> All payment data is stored by
          CloudPayments. You may find their privacy policy link(s) here:
          <a
            href="https://static.cloudpayments.ru/docs/Offer.pdf"
            target="_blank"
            >https://static.cloudpayments.ru/docs/Offer.pdf</a
          >. All personal information that you provide to Paypal must be true,
          complete and accurate, and you must notify us of any changes to such
          personal information.
        </li>
        <li>
          <strong>Information collected through our App:</strong> If you use our
          App, we also collect the following information:
          <ul>
            <li>
              <strong>Geo-Location Information:</strong> We may request access
              or permission to and track location-based information from your
              mobile device, either continuously or while you are using our App,
              to provide certain location-based services. If you wish to change
              our access or permissions, you may do so in your device's
              settings.
            </li>
            <li>
              <strong>Mobile Device Data:</strong> We automatically collect
              device information (such as your mobile device ID, model and
              manufacturer), operating system, version information and system
              configuration information, device and application identification
              numbers, browser type and version, hardware model Internet service
              provider and/or mobile carrier, and Internet Protocol (IP) address
              (or proxy server). If you are using our App, we may also collect
              information about the phone network associated with your mobile
              device, your mobile device's operating system or platform, the
              type of mobile device you use, your mobile device's unique device
              ID and information about the features of our App you accessed.
            </li>
            <li>
              <strong>Push Notifications:</strong> We may request to send you
              push notifications regarding your account or certain features of
              the App. If you wish to opt-out from receiving these types of
              communications, you may turn them off in your device's settings.
            </li>
          </ul>
        </li>
      </ul>
      <p>
        The information is primarily needed to maintain the security and
        operation of our App, for troubleshooting and for our internal analytics
        and reporting purposes.
      </p>

      <h3 class="policy-title">2. HOW DO WE USE YOUR INFORMATION?</h3>
      <p>
        In Short: We process your information for purposes based on legitimate
        business interests, the fulfillment of our contract with you, compliance
        with our legal obligations, and/or your consent. We use personal
        information collected via our App for a variety of business purposes
        described below. We process your personal information for these purposes
        in reliance on our legitimate business interests, in order to enter into
        or perform a contract with you, with your consent, and/or for compliance
        with our legal obligations. We indicate the specific processing grounds
        we rely on next to each purpose listed below.
      </p>
      <ul>
        <li>
          <strong>To facilitate account creation and logon process:</strong> If
          you choose to link your account with us to a third-party account (such
          as your Google or Facebook account), we use the information you
          allowed us to collect from those third parties to facilitate account
          creation and logon process for the performance of the contract. See
          the section below headed "HOW DO WE HANDLE YOUR SOCIAL LOGINS" for
          further information.
        </li>
        <li>
          <strong>To post testimonials:</strong> We post testimonials on our App
          that may contain personal information. Prior to posting a testimonial,
          we will obtain your consent to use your name and the consent of the
          testimonial. If you wish to update, or delete your testimonial, please
          contact us at info&#64;vivpnxxx.com and be sure to include your name,
          testimonial location, and contact information.
        </li>
        <li>
          <strong>Request feedback:</strong> We may use your information to
          request feedback and to contact you about your use of our App.
        </li>
        <li>
          <strong>To enable user-to-user communications:</strong> We may use
          your information in order to enable user-to-user communications with
          each user's consent.
        </li>
        <li>
          <strong>To manage user accounts:</strong> We may use your information
          for the purposes of managing our account and keeping it in working
          order.
        </li>
        <li>
          <strong>To send administrative information to you:</strong> We may use
          your personal information to send you product, service and new feature
          information and/or information about changes to our terms, conditions,
          and policies.
        </li>
        <li>
          <strong>To protect our Services:</strong> We may use your information
          as part of our efforts to keep our App safe and secure (for example,
          for fraud monitoring and prevention).
        </li>
        <li>
          <strong
            >To enforce our terms, conditions and policies for business
            purposes:</strong
          >
          to comply with legal and regulatory requirements or in connection with
          our contract.
        </li>
        <li>
          <strong>To respond to legal requests and prevent harm:</strong> If we
          receive a subpoena or other legal request, we may need to inspect the
          data we hold to determine how to respond.
        </li>
        <li>
          <strong>Fulfill and manage your orders:</strong> We may use your
          information to fulfill and manage your orders, payments, returns, and
          exchanges made through the App.
        </li>
        <li>
          <strong>Administer prize draws and competitions:</strong> We may use
          your information to administer prize draws and competitions when you
          elect to participate in our competitions.
        </li>
        <li>
          <strong
            >To deliver and facilitate delivery of services to the user:</strong
          >
          We may use your information to provide you with the requested service.
        </li>
        <li>
          <strong>To respond to user inquiries/offer support to users:</strong>
          We may use your information to respond to your inquiries and solve any
          potential issues you might have with the use of our Services.
        </li>
        <li>
          <strong>To send you marketing and promotional communications:</strong>
          We and/or our third-party marketing partners may use the personal
          information you send to us for our marketing purposes, if this is in
          accordance with your marketing preferences. For example, when
          expressing an interest in obtaining information about us or our App,
          subscribing to marketing or otherwise contacting us, we will collect
          personal information from you. You can opt-out of our marketing emails
          at any time (see the "WHAT ARE YOUR PRIVACY RIGHTS" below).
        </li>
        <li>
          <strong>Deliver targeted advertising to you:</strong> We may use your
          information to develop and display personalized content and
          advertising (and work with third parties who do so) tailored to your
          interests and/or location and to measure its effectiveness.
        </li>
        <li>
          <strong>For other business purposes:</strong> We may use your
          information for other business purposes, such as data analysis,
          identifying usage trends, determining the effectiveness of our
          promotional campaigns and to evaluate and improve our App, products,
          marketing and your experience. We may use and store this information
          in aggregated and anonymized form so that it is not associated with
          individual end users and does not include personal information. We
          will not use identifiable personal information without your consent.
        </li>
      </ul>

      <h3 class="policy-title">
        3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
      </h3>
      <p>
        In Short: We only share information with your consent, to comply with
        laws, to provide you with services, to protect your rights, or to
        fulfill business obligations.
      </p>
      <p>
        We may process or share your data that we hold based on the following
        legal basis:
      </p>
      <ul>
        <li>
          <strong>Consent:</strong> We may process your data if you have given
          us specific consent to use your personal information in a specific
          purpose.
        </li>
        <li>
          <strong>Legitimate Interests:</strong> We may process your data when
          it is reasonably necessary to achieve our legitimate business
          interests.
        </li>
        <li>
          <strong>Performance of a Contract:</strong> Where we have entered into
          a contract with you, we may process your personal information to
          fulfill the terms of our contract.
        </li>
        <li>
          <strong>Legal Obligations:</strong> We may disclose your information
          where we are legally required to do so in order to comply with
          applicable law, governmental requests, a judicial proceeding, court
          order, or legal process, such as in response to a court order or a
          subpoena (including in response to public authorities to meet national
          security or law enforcement requirements).
        </li>
        <li>
          <strong>Vital Interests:</strong> We may disclose your information
          where we believe it is necessary to investigate, prevent, or take
          action regarding potential violations of our policies, suspected
          fraud, situations involving potential threats to the safety of any
          person and illegal activities, or as evidence in litigation in which
          we are involved.
        </li>
      </ul>
      <p>
        More specifically, we may need to process your data or share your
        personal information in the following situations:
      </p>
      <ul>
        <li>
          <strong>Business Transfers:</strong> We may share or transfer your
          information in connection with, or during negotiations of, any merger,
          sale of company assets, financing, or acquisition of all or a portion
          of our business to another company.
        </li>
      </ul>

      <h3 class="policy-title">
        4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
      </h3>
      <p>
        In Short: We may use cookies and other tracking technologies to collect
        and store your information.
      </p>
      <p>
        We may use cookies and similar tracking technologies (like web beacons
        and pixels) to access or store information. Specific information about
        how we use such technologies and how you can refuse certain cookies is
        set out in our
        <a href="cookie_notice.html" target="_blank"
          ><strong>Cookie Notice</strong></a
        >.
      </p>

      <h3 class="policy-title">
        5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
      </h3>
      <p>
        In Short: We are not responsible for the safety of any information that
        you share with third-party providers who advertise, but are not
        affiliated with, our Website.
      </p>
      <p>
        The App may contain advertisements from third parties that are not
        affiliated with us and which may link to other websites, online services
        or mobile applications. We cannot guarantee the safety and privacy of
        data you provide to any third parties. Any data collected by third
        parties is not covered by this privacy policy. We are not responsible
        for the content or privacy and security practices and policies of any
        third parties, including other websites, services or applications that
        may be linked to or from the App. You should review the policies of such
        third parties and contact them directly to respond to your questions.
      </p>
      <ul>
        <li>
          <a href="https://policies.google.com/privacy" target="_blank"
            ><strong>AdMob</strong></a
          >
        </li>
        <li>
          <a href="https://unity3d.com/legal/privacy-policy" target="_blank"
            ><strong>Unity AD</strong></a
          >
        </li>
        <li>
          <a href="https://vungle.com/privacy" target="_blank"
            ><strong>Vungle</strong></a
          >
        </li>
        <li>
          <a href="https://pubmatic.com/legal/privacy" target="_blank"
            ><strong>PubMatic</strong></a
          >
        </li>
      </ul>

      <h3 class="policy-title">6. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
      <p>
        In Short: We keep your information for as long as necessary to fulfill
        the purposes outlined in this privacy policy unless otherwise required
        by law.
      </p>
      <p>
        We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy policy, unless a
        longer retention period is required or permitted by law (such as tax,
        accounting or other legal requirements). No purpose in this notice will
        require us keeping your personal information for longer than one (1)
        year past the termination of the user's account.
      </p>
      <p>
        When we have no ongoing legitimate business need to process your
        personal information, we will either delete or anonymize such
        information, or, if this is not possible (for example, because your
        personal information has been stored in backup archives), then we will
        securely store your personal information and isolate it from any further
        processing until deletion is possible.
      </p>

      <h3 class="policy-title">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>
      <p>
        In Short: We aim to protect your personal information through a system
        of organizational and technical security measures.
      </p>
      <p>
        We have implemented appropriate technical and organizational security
        measures designed to protect the security of any personal information we
        process. However, despite our safeguards and efforts to secure your
        information, no electronic transmission over the Internet or information
        storage technology can be guaranteed to be 100% secure, so we cannot
        promise or guarantee that hackers, cybercriminals, or other unauthorized
        third parties will not be able to defeat our security, and improperly
        collect, access, steal, or modify your information.
      </p>
      <p>
        Although we will do our best to protect your personal information,
        transmission of personal information to and from our App is at your own
        risk. You should only access the App within a secure environment.
      </p>

      <h3 class="policy-title">8. WHAT ARE YOUR PRIVACY RIGHTS?</h3>
      <p>
        In Short: In some regions, such as the European Economic Area, you have
        rights that allow you greater access to and control over your personal
        information.
      </p>
      <p>
        You may review, change, or terminate your account at any time. In some
        regions (like the European Economic Area), you have certain rights under
        applicable data protection laws. These may include the right:
      </p>
      <ul>
        <li>
          to request access and obtain a copy of your personal information,
        </li>
        <li>to request rectification or erasure,</li>
        <li>to restrict the processing of your personal information, and</li>
        <li>if applicable, to data portability.</li>
      </ul>
      <p>
        In certain circumstances, you may also have the right to object to the
        processing of your personal information. To make such a request, please
        use the contact details provided below. We will consider and act upon
        any request in accordance with applicable data protection laws. If we
        are relying on your consent to process your personal information, you
        have the right to withdraw your consent at any time.
      </p>
      <p>
        Please note however that this will not affect the lawfulness of the
        processing before its withdrawal, nor will it affect the processing of
        your personal information conducted in reliance on lawful processing
        grounds other than consent.
      </p>
      <p>
        If you are resident in the European Economic Area and you believe we are
        unlawfully processing your personal information, you also have the right
        to complain to your local data protection supervisory authority. You can
        find their contact details here:
        <a
          href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
          target="_blank"
          >http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a
        >.
      </p>
      <p>
        If you are resident in Switzerland, the contact details for the data
        protection authorities are available here:
        <a href="https://www.edoeb.admin.ch/edoeb/en/home.html" target="_blank"
          >https://www.edoeb.admin.ch/edoeb/en/home.html</a
        >.
      </p>
      <p>
        If you have questions or comments about your privacy rights, you may
        email us at <strong>info&#64;vivpnxxx.com</strong>.
      </p>
      <h4>Account Information</h4>
      <p>
        If you would at any time like to review or change the information in
        your account or terminate your account, you can:
      </p>
      <ul>
        <li>Log in to your account settings and update your user account.</li>
        <li>Contact us using the contact information provided.</li>
      </ul>
      <p>
        Upon your request to terminate your account, we will deactivate or
        delete your account and information from our active databases. However,
        we may retain some information in our files to prevent fraud,
        troubleshoot problems, assist with any investigations, enforce our Terms
        of Use and/or comply with applicable legal requirements.
      </p>
      <h4>Cookies and similar technologies:</h4>
      <p>
        Most Web browsers are set to accept cookies by default. If you prefer,
        you can usually choose to set your browser to remove cookies and to
        reject cookies. If you choose to remove cookies or reject cookies, this
        could affect certain features or services of our App.
      </p>
      <h4>Opting out of email marketing:</h4>
      <p>
        You can unsubscribe from our marketing email list at any time by
        clicking on the unsubscribe link in the emails that we send or by
        contacting us using the details provided below. You will then be removed
        from the marketing email list – however, we may still communicate with
        you, for example to send you service-related emails that are necessary
        for the administration and use of your account, to respond to service
        requests, or for other non-marketing purposes.
      </p>
      <p>To otherwise opt-out, you may:</p>
      <ul>
        <li>Contact us using the contact information provided.</li>
      </ul>

      <h3 class="policy-title">9. CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
      <p>
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track (“DNT”) feature or setting you can
        activate to signal your privacy preference not to have data about your
        online browsing activities monitored and collected.
      </p>
      <p>
        At this stage, no uniform technology standard for recognizing and
        implementing DNT signals has been finalized. As such, we do not
        currently respond to DNT browser signals or any other mechanism that
        automatically communicates your choice not to be tracked online.
      </p>
      <p>
        If a standard for online tracking is adopted that we must follow in the
        future, we will inform you about that practice in a revised version of
        this privacy policy.
      </p>

      <h3 class="policy-title">
        10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
      </h3>
      <p>
        In Short: <strong>Yes</strong>, if you are a resident of California, you
        are granted specific rights regarding access to your personal
        information. California Civil Code Section 1798.83, also known as the
        “Shine The Light” law, permits our users who are California residents to
        request and obtain from us, once a year and free of charge, information
        about categories of personal information (if any) we disclosed to third
        parties for direct marketing purposes and the names and addresses of all
        third parties with which we shared personal information in the
        immediately preceding calendar year.
      </p>
      <p>
        If you are a California resident and would like to make such a request,
        please submit your request in writing to us using the contact
        information provided below.
      </p>
      <ul>
        <li>
          If you are under 18 years of age, reside in California, and have a
          registered account with the App, you have the right to request removal
          of unwanted data that you publicly post on the App.
        </li>
        <li>
          To request removal of such data, please contact us using the contact
          information provided below, and include the email address associated
          with your account and a statement that you reside in California.
        </li>
        <li>
          We will make sure the data is not publicly displayed on the App, but
          please be aware that the data may not be completely or comprehensively
          removed from all our systems (e.g. backups, etc.).
        </li>
      </ul>

      CCPA Privacy policy The California Code of Regulations defines a
      "resident" as: (1) every individual who is in the State of California for
      other than a temporary or transitory purpose and (2) every individual who
      is domiciled in the State of California who is outside the State of
      California for a temporary or transitory purpose All other individuals are
      defined as "non-residents." If this definition of "resident" applies to
      you, certain rights and obligations apply regarding your personal
      information. What categories of personal information do we collect? We
      have collected the following categories of personal information in the
      past twelve (12) months: Category Examples Collected A. Identifiers
      Contact details, such as email address YES B. Personal information
      categories listed in the California Customer Records statute Name, contact
      information, education, employment, employment history and financial
      information NO C. Protected classification characteristics under
      California or federal law Gender and date of birth NO D. Commercial
      information Transaction information, purchase history, financial details
      and payment information NO E. Biometric information Fingerprints and
      voiceprints NO F. Internet or other similar network activity Browsing
      history, search history, online behavior, interest data, and interactions
      with our and other websites, applications, systems and advertisements NO
      G. Geolocation data Device location YES H. Audio, electronic, visual,
      thermal, olfactory, or similar information Images and audio, video or call
      recordings created in connection with our business activities NO I.
      Professional or employment-related information Business contact details in
      order to provide you our services at a business level, job title as well
      as work history and professional qualifications if you apply for a job
      with us NO J. Education Information Student records and directory
      information NO K. Inferences drawn from other personal information
      Inferences drawn from any of the collected personal information listed
      above to create a profile or summary about, for example, an individualÕs
      preferences and characteristics NO We may also collect other personal
      information outside of these categories in instances where you interact
      with us in-person, online, or by phone or mail in the context of: •
      Receiving help through our customer support channels • Participation in
      customer surveys or contests; and • Facilitation in the delivery of our
      Services and to respond to your inquiries How do we use and share your
      personal information? More information about our data collection and
      sharing practices can be found in this privacy policy. You may contact us
      by email at info&#64;vivpnxxx.com, or by referring to the contact details at
      the bottom of this document. If you are using an authorized agent to
      exercise your right to opt-out, we may deny a request if the authorized
      agent does not submit proof that they have been validly authorized to act
      on your behalf. Will your information be shared with anyone else? We may
      disclose your personal information with our service providers pursuant to
      a written contract between us and each service provider. Each service
      provider is a for- profit entity that processes the information on our
      behalf. We may use your personal information for our own business
      purposes, such as for undertaking internal research for technological
      development and demonstration. This is not considered to be "selling" of
      your personal data. ViVpn has not disclosed or sold any personal
      information to third parties for a business or commercial purpose in the
      preceding twelve (12) months. ViVpn will not sell personal information in
      the future belonging to website visitors, users and other consumers. Your
      rights with respect to your personal data Right to request deletion of the
      data - Request to delete You can ask for the deletion of your personal
      information. If you ask us to delete your personal information, we will
      respect your request and delete your personal information, subject to
      certain exceptions provided by law, such as (but not limited to) the
      exercise by another consumer of his or her right to free speech, our
      compliance requirements resulting from a legal obligation or any
      processing that may be required to protect against illegal activities.
      Right to be informed - Request to know Depending on the circumstances, you
      have a right to know: • whether we collect and use your personal
      information; • the categories of personal information that we collect; •
      the purposes for which the collected personal information is used; •
      whether we sell your personal information to third parties; • the
      categories of personal information that we sold or disclosed for a
      business purpose; • the categories of third parties to whom the personal
      information was sold or disclosed for a business purpose; and • the
      business or commercial purpose for collecting or selling personal
      information. In accordance with applicable law, we are not obligated to
      provide or delete consumer information that is de-identified in response
      to a consumer request or to re-identify individual data to verify a
      consumer request. Right to Non-Discrimination for the Exercise of a
      Consumer's Privacy Rights We will not discriminate against you if you
      exercise your privacy rights. Verification process Upon receiving your
      request, we will need to verify your identity to determine you are the
      same person about whom we have the information in our system. These
      verification efforts require us to ask you to provide information so that
      we can match it with the information you have previously provided us. For
      instance, depending on the type of request you submit, we may ask you to
      provide certain information so that we can match the information you
      provide with the information we already have on file, or we may contact
      you through a communication method (e.g. phone or email) that you have
      previously provided to us. We may also use other verification methods as
      the circumstances dictate. We will only use personal information provided
      in your request to verify your identity or authority to make the request.
      To the extent possible, we will avoid requesting additional information
      from you for the purposes of verification. If, however, if we cannot
      verify your identity from the information already maintained by us, we may
      request that you provide additional information for the purposes of
      verifying your identity, and for security or fraud- prevention purposes.
      We will delete such additionally provided information as soon as we finish
      verifying you. Other privacy rights • you may object to the processing of
      your personal data • you may request correction of your personal data if
      it is incorrect or no longer relevant, or ask to restrict the processing
      of the data • you can designate an authorized agent to make a request
      under the CCPA on your behalf. We may deny a request from an authorized
      agent that does not submit proof that they have been validly authorized to
      act on your behalf in accordance with the CCPA. • you may request to
      opt-out from future selling of your personal information to third parties.
      Upon receiving a request to opt-out, we will act upon the request as soon
      as feasibly possible, but no later than 15 days from the date of the
      request submission. To exercise these rights, you can contact us by email
      at info&#64;vivpnxxx.com, or by referring to the contact details at the bottom
      of this document. If you have a complaint about how we handle your data,
      we would like to hear from you.

      <h3 class="policy-title">11. DO WE MAKE UPDATES TO THIS NOTICE?</h3>
      <p>
        In Short: <strong>Yes</strong>, we will update this notice as necessary
        to stay compliant with relevant laws.
      </p>
      <p>
        We may update this privacy policy from time to time. The updated version
        will be indicated by an updated “Revised” date and the updated version
        will be effective as soon as it is accessible. If we make material
        changes to this privacy policy, we may notify you either by prominently
        posting a notice of such changes or by directly sending you a
        notification.
      </p>
      <p>
        We encourage you to review this privacy policy frequently to be informed
        of how we are protecting your information.
      </p>

      <h3 class="policy-title">
        12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
      </h3>
      <p>
        If you have questions or comments about this notice, you may contact our
        Data Protection Officer (DPO), Viktoriia Khvorostina, by email at
        <a href="mailto:info@vivpnxxx.com" target="_blank">info&#64;vivpnxxx.com</a
        >, Viktoriia Khvorostina, Yerevan , 13 Argishti str., Apt. 39,AM
      </p>

      <h3 class="policy-title">
        HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </h3>
      <p>
        Based on the applicable laws of your country, you may have the right to
        request access to the personal information we collect from you, change
        that information, or delete it in some circumstances. To request to
        review, update, or delete your personal information, please submit a
        request form by sending email to
        <a href="mailto:info@vivpnxxx.com" target="_blank">info&#64;vivpnxxx.com</a
        >.
      </p>
    </div>
  </ng-container>

  <!--Английский язык-->
  <ng-container *ngIf="currentLanguage() === 'ru'">
    <div>
      <h1 class="policy-title">Политика конфиденциальности&nbsp;</h1>
      <p>
        Благодарим вас за выбор нашего сообщества в рамках ИП Иванов Олег
        Владимирович ("Компания", "мы", "нас" или "наша"). Мы стремимся защитить
        вашу личную информацию и ваше право на конфиденциальность. Если у вас
        возникли вопросы или опасения относительно настоящей политики
        конфиденциальности или наших практик в отношении вашей личной
        информации, пожалуйста, свяжитесь с нами по адресу info&#64;vivpnxxx.com.
        Когда вы используете наше мобильное или ПК-приложение, как это может
        быть («Приложение»), и более обще, используете любые из наших услуг
        («Услуги», которые включают Приложение), мы ценим то, что вы доверяете
        нам своей личной информацией. Мы очень серьезно относимся к вашей
        конфиденциальности. В настоящей политике конфиденциальности мы стараемся
        объяснить вам наиболее понятным образом, какую информацию мы собираем,
        как мы ее используем и какие права у вас в отношении этой информации. Мы
        надеемся, что вы уделите время, чтобы внимательно ознакомиться с ней,
        так как это важно. Если вы не согласны с какими-либо условиями настоящей
        политики конфиденциальности, пожалуйста, немедленно прекратите
        использование наших Услуг. Настоящая политика конфиденциальности
        применяется ко всей информации, собранной через наши Услуги (которые,
        как указано выше, включают наше Приложение), а также ко всем связанным
        услугам, продажам, маркетингу или мероприятиям. Пожалуйста, внимательно
        прочитайте настоящую политику конфиденциальности, так как она поможет
        вам понять, что мы делаем с информацией, которую собираем.&nbsp;
      </p>
      <p>СОДЕРЖАНИЕ&nbsp;</p>
      <p>1. КАКУЮ ИНФОРМАЦИЮ МЫ СОБИРАЕМ?&nbsp;</p>
      <p>2. КАК МЫ ИСПОЛЬЗУЕМ ВАШУ ИНФОРМАЦИЮ?&nbsp;</p>
      <p>3. БУДЕТ ЛИ ВАША ИНФОРМАЦИЯ РАЗДЕЛЕНА С КЕМ-ТО?&nbsp;</p>
      <p>4. МЫ ИСПОЛЬЗУЕМ КУКИ И ДРУГИЕ ТЕХНОЛОГИИ ОТСЛЕЖИВАНИЯ?&nbsp;</p>
      <p>5. КАКОЕ У НАС ОТНОШЕНИЕ К САЙТАМ ТРЕТЬИХ СТОРОН?&nbsp;</p>
      <p>6. КАК ДОЛГО МЫ ХРАНИМ ВАШУ ИНФОРМАЦИЮ?</p>
      <p>&nbsp;7. КАК МЫ ОБЕСПЕЧИВАЕМ БЕЗОПАСНОСТЬ ВАШЕЙ ИНФОРМАЦИИ?&nbsp;</p>
      <p>8. КАКИЕ У ВАС ПРАВА КОНФИДЕНЦИАЛЬНОСТИ?&nbsp;</p>
      <p>9. УПРАВЛЕНИЕ ФУНКЦИЯМИ "НЕ СЛЕДИТЬ"&nbsp;</p>
      <p>
        10. ЕСТЬ ЛИ У ЖИТЕЛЕЙ КАЛИФОРНИИ ОСОБЫЕ ПРАВА КОНФИДЕНЦИАЛЬНОСТИ?&nbsp;
      </p>
      <p>11. ОБНОВЛЯЕМ ЛИ МЫ ДАННУЮ ИНФОРМАЦИЮ?&nbsp;</p>
      <p>
        12. КАК ВЫ МОЖЕТЕ СВЯЗАТЬСЯ С НАМИ ПО ВОПРОСАМ ДАННОГО ОБЪЯВЛЕНИЯ?&nbsp;
      </p>
      <h2 class="policy-title">1. КАКУЮ ИНФОРМАЦИЮ МЫ СОБИРАЕМ?&nbsp;</h2>
      <p>
        Информация, которую вы предоставляете нам лично Вкратце: Мы собираем
        информацию, которую вы предоставляете нам. Мы собираем личную
        информацию, которую вы добровольно предоставляете нам при регистрации в
        Приложении, проявлении интереса к получению информации о нас или наших
        продуктах и услугах, участии в деятельности в Приложении или при
        обращении к нам. Личная информация, которую мы собираем, зависит от
        контекста вашего взаимодействия с нами и Приложением, выбора, который вы
        сделали, а также продуктов и функций, которые вы используете. Личная
        информация, которую мы собираем, может включать следующее: Личная
        информация, предоставленная вами. Мы собираем адреса электронной почты.
        Данные о платежах. Все данные о платежах хранятся CloudPayments. Вы
        можете найти ссылки на их политику конфиденциальности здесь:
        https://static.cloudpayments.ru/docs/ Offer.pdf Вся предоставленная вами
        личная информация должна быть достоверной, полной и точной, и вы должны
        уведомить нас о любых изменениях такой личной информации. Информация,
        собираемая через наше Приложение Вкратце: Мы собираем информацию о вашем
        местоположении, мобильном устройстве, уведомлениях push при
        использовании нашего Приложения. Если вы используете наше Приложение, мы
        также собираем следующую информацию: • Информация о местоположении. Мы
        можем запрашивать доступ или разрешение для отслеживания информации о
        местоположении на вашем мобильном устройстве, либо непрерывно, либо во
        время использования нашего Приложения, чтобы предоставлять определенные
        местоположенные услуги. Если вы хотите изменить наш доступ или
        разрешения, вы можете сделать это в настройках вашего устройства. •
        Данные мобильного устройства. Мы автоматически собираем информацию о
        устройстве (такую как идентификатор мобильного устройства, модель и
        производитель), операционную систему, информацию о версии и конфигурации
        системы, идентификаторы устройств и приложений, тип и версию браузера,
        модель оборудования, поставщика интернет-услуг и/или мобильного
        оператора, а также IP-адрес (или прокси-сервер). Если вы используете
        наше Приложение, мы также можем собирать информацию о телефонной сети,
        связанной с вашим мобильным устройством, операционной системе или
        платформе вашего мобильного устройства, типе мобильного устройства,
        которое вы используете, уникальном идентификаторе вашего мобильного
        устройства и информации о функциях нашего Приложения, к которым вы
        получили доступ. • Уведомления push. Мы можем запросить отправку вам
        push-уведомлений о вашей учетной записи или определенных функциях
        Приложения. Если вы хотите отказаться от получения такого рода
        сообщений, вы можете отключить их в настройках вашего устройства. Эта
        информация в основном требуется для обеспечения безопасности и
        функционирования нашего Приложения, для устранения неисправностей, а
        также для наших внутренних аналитических и отчетных целей.&nbsp;
      </p>
      <h2 class="policy-title">2. КАК МЫ ИСПОЛЬЗУЕМ ВАШУ ИНФОРМАЦИЮ?&nbsp;</h2>
      <p>
        Вкратце: Мы обрабатываем вашу информацию на основе законных коммерческих
        интересов, исполнения нашего договора с вами, соблюдения наших законных
        обязательств и/или вашего согласия. Мы используем личную информацию,
        собранную через наше Приложение, в различных деловых целях, описанных
        ниже. Мы обрабатываем вашу личную информацию для этих целей, опираясь на
        наши законные коммерческие интересы, для вступления в договор или
        выполнения его условий с вами, с вашего согласия и/или в соответствии с
        нашими законными обязательствами. Мы указываем конкретные основания
        обработки рядом с каждой из нижеперечисленных целей. Мы используем
        собранную или полученную информацию: • Для упрощения процесса создания
        учетной записи и входа в систему. Если вы решите связать свою учетную
        запись с некоторой учетной записью третьей стороны (такой как учетная
        запись Google или Facebook), мы используем информацию, разрешенную вами
        для сбора от этих третьих сторон, чтобы упростить процесс создания
        учетной записи и входа в систему в соответствии с условиями договора.
        Дополнительную информацию см. в разделе «КАК МЫ ОБРАБАТЫВАЕМ ВАШИ
        СОЦИАЛЬНЫЕ ВХОДЫ» ниже. • Для размещения отзывов. Мы размещаем отзывы на
        нашем Приложении, которые могут содержать личную информацию. Перед
        размещением отзыва мы получим ваше согласие на использование вашего
        имени и отзыва. Если вы хотите обновить или удалить свой отзыв,
        пожалуйста, свяжитесь с нами по адресу info&#64;vivpnxxx.com и обязательно
        укажите ваше имя, место размещения отзыва и контактную информацию. •
        Запрос обратной связи. Мы можем использовать вашу информацию для запроса
        обратной связи и связи с вами относительно вашего использования нашего
        Приложения. • Для обеспечения общения между пользователями. Мы можем
        использовать вашу информацию для обеспечения общения между
        пользователями с согласия каждого пользователя. • Для управления
        учетными записями пользователей. Мы можем использовать вашу информацию в
        целях управления нашей учетной записью и поддержания ее
        работоспособности. • Для отправки вам административной информации. Мы
        можем использовать вашу личную информацию, чтобы отправлять вам
        информацию о продуктах, услугах и новых функциях, а также информацию о
        изменениях в наших условиях, положениях и правилах. • Для защиты наших
        Сервисов. Мы можем использовать вашу информацию в рамках наших усилий по
        обеспечению безопасности и защиты нашего Приложения (например, для
        мониторинга и предотвращения мошенничества). • Для обеспечения
        исполнения наших условий, положений и правил в коммерческих целях, для
        соблюдения юридических и регуляторных требований или в связи с нашим
        договором. • Для ответа на правовые запросы и предотвращения ущерба.
        Если мы получим повестку или иной правовой запрос, мы можем быть
        вынуждены осмотреть данные, которые мы имеем, чтобы определить, как на
        него ответить. • Исполнение и управление ваших заказов. Мы можем
        использовать вашу информацию для исполнения и управления вашими
        заказами, платежами, возвратами и обменами, сделанными через Приложение.
        • Управление розыгрышами и конкурсами. Мы можем использовать вашу
        информацию для управления розыгрышами и конкурсами при вашем участии в
        наших конкурсах. • Доставка и обеспечение доставки услуг пользователю.
        Мы можем использовать вашу информацию, чтобы предоставить вам
        запрошенную услугу. • Ответ на запросы пользователей/предложение
        поддержки пользователям. Мы можем использовать вашу информацию, чтобы
        ответить на ваши запросы и решить любые возможные проблемы, которые у
        вас могут возникнуть при использовании наших Сервисов. • Для отправки
        вам маркетинговых и рекламных сообщений. Мы и/или наши партнеры по
        маркетингу третьей стороны можем использовать личную информацию, которую
        вы отправляете нам, в маркетинговых целях, если это соответствует вашим
        предпочтениям в области маркетинга. Например, когда вы проявляете
        интерес к получению информации о нас или нашем Приложении,
        подписываетесь на маркетинговые материалы или связываетесь с нами, мы
        будем собирать личную информацию от вас. Вы можете отказаться от
        получения наших маркетинговых электронных писем в любое время (см.
        «КАКИЕ ПРАВА ПО ОТНОШЕНИЮ К ВАШЕЙ ЛИЧНОЙ ИНФОРМАЦИИ ОБ ИНФОРМАЦИИ»
        ниже). • Предоставление вам целевой рекламы. Мы можем использовать вашу
        информацию для разработки и отображения персонализированного контента и
        рекламы (и работать с третьими сторонами, которые это делают) в
        соответствии с вашими интересами и/или местоположением и для оценки ее
        эффективности. • Для других деловых целей. Мы можем использовать вашу
        информацию для других деловых целей, таких как анализ данных,
        исследования рынка и оценка эффективности наших рекламных
        кампаний.&nbsp;
      </p>
      <h3 class="policy-title">
        3. БУДЕТ ЛИ ВАША ИНФОРМАЦИЯ РАЗДЕЛЕНА С КЕМ-ТО?
      </h3>
      <p>
        &nbsp;Вкратце: Мы не продаем, не арендуем и не обмениваем вашу личную
        информацию с третьими сторонами, кроме случаев, описанных в настоящей
        политике конфиденциальности. Мы не продаем, не арендуем и не обмениваем
        вашу личную информацию с третьими сторонами без вашего согласия, кроме
        случаев, описанных в настоящей политике конфиденциальности. Мы можем
        раскрывать вашу личную информацию третьим сторонам только в следующих
        случаях: • Поставщикам услуг. Мы можем раскрывать вашу информацию
        поставщикам услуг, связанным с оказанием услуг в нашем имени,
        выполнением заказов или предоставлением поддержки пользователям.
        Например, мы можем использовать сторонних поставщиков услуг для
        обработки платежей, хранения данных, аналитики данных, обеспечения
        безопасности, доставки электронной почты, хостинга, обслуживания
        клиентов и других услуг. Такие поставщики услуг будут обязаны соблюдать
        конфиденциальность и безопасность вашей личной информации и могут
        использовать вашу личную информацию только в соответствии с нашими
        указаниями. • Правовые требования. Мы можем раскрывать вашу информацию,
        если мы считаем, что это необходимо для соблюдения применимого закона,
        юридического процесса, судебного распоряжения или другого правового
        требования. • Защита прав и интересов. Мы можем раскрывать вашу
        информацию, если мы считаем, что это необходимо для защиты ваших прав,
        безопасности или имущества, наших прав, безопасности или имущества или
        прав, безопасности или имущества других лиц, в том числе для обмена
        информацией с другими компаниями и организациями для целей защиты от
        мошенничества и уменьшения кредитного риска. • С разрешениями. Мы можем
        раскрывать вашу информацию с вашего разрешения или согласия. • При
        слиянии или приобретении. В случае объединения, продажи активов,
        финансирования или приобретения всей или части нашей компании другой
        компанией, ваша информация может быть передана нашими совладельцами или
        новому владельцу или получателю услуг. В этом случае мы будем принимать
        разумные меры для уведомления вас о любых изменениях владения вашей
        личной информацией или ее использования. Мы также можем раскрывать
        агрегированную или обезличенную информацию, которая не может быть
        использована для идентификации конкретного пользователя, в коммерческих
        целях.&nbsp;
      </p>
      <h2 class="policy-title">
        4. МЫ ИСПОЛЬЗУЕМ КУКИ И ДРУГИЕ ТЕХНОЛОГИИ ОТСЛЕЖИВАНИЯ?&nbsp;
      </h2>
      <p>
        Вкратце: Мы можем использовать файлы cookie и другие технологии
        отслеживания для сбора и хранения вашей информации. Мы можем
        использовать файлы cookie и аналогичные технологии отслеживания
        (например, веб-маяки, пиксели и технологии отслеживания мобильных
        приложений) для сбора и хранения информации, когда вы используете наше
        Приложение и предоставляемые услуги. Файлы cookie - это небольшие
        текстовые файлы, содержащие уникальные идентификаторы, которые могут
        быть размещены на вашем компьютере, мобильном устройстве или другом
        устройстве, чтобы идентифицировать ваш браузер или устройство, когда вы
        используете наше Приложение и предоставляемые услуги. Мы также можем
        использовать эти технологии для сбора и хранения информации, когда вы
        взаимодействуете с услугами, предлагаемыми нашими партнерами маркетинга
        третьей стороны, такими как рекламные сети. Мы используем файлы cookie
        для различных целей, включая: • Обеспечение функциональности Приложения.
        Мы можем использовать файлы cookie для обеспечения функциональности
        Приложения, такой как сохранение настроек пользователя и предоставление
        персонализированного контента. • Аналитика и исследования. Мы можем
        использовать файлы cookie для аналитических целей, чтобы отслеживать и
        анализировать использование нашего Приложения, предоставлять отчеты о
        деятельности и улучшать наши услуги и функциональность. • Реклама. Мы
        можем использовать файлы cookie для предоставления вам
        персонализированной рекламы и содержания на основе ваших интересов и
        предпочтений. Мы также можем использовать файлы cookie для измерения
        эффективности рекламы и анализа поведения пользователей в контексте
        рекламы. • Безопасность. Мы можем использовать файлы cookie для защиты
        от мошенничества и обеспечения безопасности нашего Приложения и
        пользователей. Вы можете настроить свой браузер таким образом, чтобы
        отклонить все файлы cookie или предупреждать вас о их размещении. Однако
        отключение файлов cookie может ограничить функциональность нашего
        Приложения и влиять на ваш опыт использования.&nbsp;
      </p>
      <h2 class="policy-title">
        5. КАКОЕ У НАС ОТНОШЕНИЕ К СТОРОННИМ ВЕБ-САЙТАМ?&nbsp;
      </h2>
      <p>
        Вкратце: Мы не несем ответственности за безопасность информации, которую
        вы передаете сторонним поставщикам, рекламирующимся на нашем Веб-сайте,
        но не связанным с нами. В Приложении могут содержаться рекламные
        объявления от третьих сторон, не связанных с нами, которые могут
        содержать ссылки на другие веб-сайты, онлайн-сервисы или мобильные
        приложения. Мы не можем гарантировать безопасность и конфиденциальность
        данных, которые вы предоставляете третьим сторонам. Любые данные,
        собранные третьими сторонами, не покрываются настоящей политикой
        конфиденциальности. Мы не несем ответственности за содержание, политику
        конфиденциальности и практики безопасности сторонних сторон, включая
        другие вебсайты, сервисы или приложения, на которые может быть размещена
        ссылка в Приложении. Вы должны ознакомиться с политиками таких сторонних
        сторон и обращаться к ним напрямую для получения ответов на ваши
        вопросы. AdMob: https://policies.google.com/privacy Unity AD:
        https://unity3d.com/legal/privacy-policy Vungle:
        https://vungle.com/privacy PubMatic:
        https://pubmatic.com/legal/privacy&nbsp;
      </p>
      <h2 class="policy-title">
        6. КАК ДОЛГО МЫ ХРАНИМ ВАШУ ИНФОРМАЦИЮ?&nbsp;
      </h2>
      <p>
        Вкратце: Мы храним вашу информацию столько времени, сколько необходимо
        для достижения целей, указанных в настоящей политике конфиденциальности,
        если иное не предусмотрено законом. Мы будем хранить вашу личную
        информацию только в течение времени, необходимого для достижения целей,
        установленных в настоящей политике конфиденциальности, если более
        длительный срок хранения не требуется или не разрешен законом (например,
        для налоговых, бухгалтерских или других юридических требований). Ни одна
        из целей, описанных в настоящем уведомлении, не потребует хранения вашей
        личной информации дольше, чем один (1) год после прекращения учетной
        записи пользователя. Когда у нас нет дальнейшей законной необходимости
        обрабатывать вашу личную информацию, мы либо удалим или анонимизируем
        такую информацию, либо, если это невозможно (например, если ваша личная
        информация была сохранена в резервных архивах), мы надежно сохраним вашу
        личную информацию, изолируя ее от любой дальнейшей обработки до
        возможности удаления.&nbsp;
      </p>
      <h2 class="policy-title">
        7. КАК МЫ ОБЕСПЕЧИВАЕМ БЕЗОПАСНОСТЬ ВАШЕЙ ИНФОРМАЦИИ?&nbsp;
      </h2>
      <p>
        Вкратце: Мы стремимся защитить вашу личную информацию с помощью системы
        организационных и технических мер безопасности. Мы применяем
        соответствующие технические и организационные меры безопасности,
        предназначенные для защиты безопасности любой обрабатываемой нами личной
        информации. Однако, несмотря на наши меры защиты и усилия по обеспечению
        безопасности вашей информации, ни одна электронная передача через
        интернет или технология хранения информации не может гарантировать
        абсолютную безопасность на 100%, поэтому мы не можем обещать или
        гарантировать, что хакеры, киберпреступники или другие неавторизованные
        третьи стороны не смогут обойти нашу систему защиты и неправомерно
        собрать, получить доступ, украсть или изменить вашу информацию. Хотя мы
        будем прилагать все усилия для защиты вашей личной информации, передача
        личной информации в и из нашего приложения осуществляется на ваш риск.
        Вы должны получать доступ к приложению только в безопасной среде.&nbsp;
      </p>
      <h2 class="policy-title">
        8. КАКИЕ У ВАС ПРАВА НА КОНФИДЕНЦИАЛЬНОСТЬ?&nbsp;
      </h2>
      <p>
        Вкратце: В некоторых регионах, таких как Европейская экономическая зона,
        у вас есть права, которые позволяют вам получить больший доступ к вашей
        личной информации и контролировать ее. Вы можете в любое время
        просмотреть, изменить или прекратить свою учетную запись. В некоторых
        регионах (например, в Европейской экономической зоне) у вас есть
        определенные права в соответствии с применимыми законами о защите
        данных. Это может включать право (i) запросить доступ и получить копию
        вашей личной информации, (ii) запросить исправление или удаление, (iii)
        ограничить обработку вашей личной информации, и (iv) при наличии
        оснований, применить право на передачу данных. В определенных случаях у
        вас также может быть право возражать против обработки вашей личной
        информации. Для подачи такого запроса, пожалуйста, используйте указанные
        ниже контактные данные. Мы рассмотрим и выполним любой запрос в
        соответствии с применимыми законами о защите данных. Если мы
        основываемся на вашем согласии для обработки вашей личной информации, у
        вас есть право отозвать свое согласие в любое время. Однако, пожалуйста,
        обратите внимание, что это не повлияет на законность обработки до его
        отзыва, а также не повлияет на обработку вашей личной информации,
        основанной на других законных основаниях, кроме согласия. Если вы
        проживаете в Европейской экономической зоне и считаете, что мы
        неправомерно обрабатываем вашу личную информацию, у вас также есть право
        подать жалобу в орган по защите данных вашей страны. Их контактные
        данные можно найти здесь: http://
        ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
        Если вы проживаете в Швейцарии, контактные данные органов по защите
        данных доступны здесь: https://www.edoeb.admin.ch/edoeb/en/home.html.
        Если у вас есть вопросы или комментарии относительно ваших прав на
        конфиденциальность, вы можете написать нам по адресу info&#64;vivpnxxx.com.
        Информация об учетной записи Если вам когда-либо захочется просмотреть
        или изменить информацию в вашей учетной записи или прекратить ее
        использование, вы можете: • Войти в настройки своей учетной записи и
        обновить свою пользовательскую учетную запись. • Связаться с нами,
        используя предоставленную контактную информацию. По вашему запросу о
        прекращении использования вашей учетной записи мы деактивируем или
        удалим вашу учетную запись и информацию из наших активных баз данных.
        Однако мы можем сохранить некоторую информацию в наших файлах для
        предотвращения мошенничества, устранения проблем, оказания помощи в
        расследованиях, обеспечения соблюдения наших условий использования и/или
        выполнения применимых юридических требований. Куки и подобные
        технологии: Большинство веб-браузеров настроены на автоматическое
        принятие файлов cookie. Если вы предпочитаете, вы обычно можете выбрать
        настройки браузера для удаления файлов cookie или отказа от их
        использования. Если вы выберете удаление файлов cookie или отказ от их
        использования, это может повлиять на определенные функции или услуги
        нашего приложения. Отказ от электронной рассылки: Вы можете отписаться
        от нашего списка рассылки в любое время, щелкнув на ссылке "Отказаться
        от подписки" в электронных письмах, которые мы отправляем, или
        связавшись с нами по указанным ниже контактным данным. Таким образом, вы
        будете удалены из списка рассылки - однако мы все равно можем
        поддерживать с вами связь, например, для отправки вам электронных писем,
        не связанных с маркетингом, но необходимых для администрирования и
        использования вашей учетной записи, для реагирования на запросы в
        области обслуживания или для других не связанных с маркетингом целей.
        Для отказа от рассылки вы можете также: • Связаться с нами, используя
        предоставленную контактную информацию.&nbsp;
      </p>
      <h2 class="policy-title">9. УПРАВЛЕНИЕ ФУНКЦИЯМИ "DO-NOT-TRACK"&nbsp;</h2>
      <p>
        Большинство веб-браузеров и некоторые мобильные операционные системы и
        мобильные приложения включают функцию или настройку "Do-Not-Track"
        (DNT), которую можно активировать, чтобы указать ваше предпочтение
        относительно конфиденциальности и не разрешать мониторинг и сбор данных
        о ваших онлайн-активностях. На данный момент не установлен единый
        стандарт технологии для распознавания и применения сигналов DNT. Поэтому
        в настоящее время мы не реагируем на сигналы браузера DNT или любой
        другой механизм, который автоматически указывает на ваш выбор не
        отслеживать вас в Интернете. Если будет принят стандарт для
        онлайн-отслеживания, которому мы должны будем следовать в будущем, мы
        сообщим вам об этой практике в пересмотренной версии настоящей политики
        конфиденциальности.&nbsp;
      </p>
      <h2 class="policy-title">
        10. ИМЕЮТ ЛИ ЖИТЕЛИ КАЛИФОРНИИ ОСОБЫЕ ПРАВА НА КОНФИДЕНЦИАЛЬНОСТЬ?&nbsp;
      </h2>
      <p>
        Кратко говоря: Да, если вы являетесь жителем Калифорнии, вам
        предоставляются особые права в отношении доступа к вашей персональной
        информации. Согласно Гражданскому кодексу Калифорнии, раздел 1798.83,
        также известному как "Shine The Light" (Пролить свет) закон, наши
        пользователи, которые являются жителями Калифорнии, могут ежегодно и
        бесплатно запросить и получить информацию о категориях персональной
        информации (если таковая имеется), которую мы раскрываем третьим лицам в
        целях прямого маркетинга, а также имена и адреса всех третьих лиц, с
        которыми мы делились персональной информацией в предыдущем календарном
        году. Если вы являетесь жителем Калифорнии и хотите сделать такой
        запрос, пожалуйста, представьте ваш запрос в письменной форме, используя
        предоставленную контактную информацию ниже. Если вам менее 18 лет, вы
        проживаете в Калифорнии и имеете зарегистрированную учетную запись в
        приложении, у вас есть право запросить удаление нежелательных данных,
        которые вы публично разместили в приложении. Чтобы запросить удаление
        таких данных, пожалуйста, свяжитесь с нами, используя предоставленные
        ниже контактные данные, и включите адрес электронной почты, связанный с
        вашей учетной записью, а также заявление о том, что вы проживаете в
        Калифорнии. Мы убедимся, что данные не будут публично отображаться в
        приложении, но, пожалуйста, имейте в виду, что данные могут не быть
        полностью или всесторонне удалены из всех наших систем (например,
        резервных копий и т. д.). Политика конфиденциальности CCPA Кодекс
        регулирования Калифорнии определяет "жителя" следующим образом: (1)
        каждое физическое лицо, находящееся в штате Калифорния, не имеющее
        временного или кратковременного характера пребывания, и (2) каждое
        физическое лицо, проживающее в штате Калифорния, находящееся за
        пределами штата Калифорния временно или кратковременно Все остальные
        физические лица определяются как "нерезиденты". Если эта определение
        "резидента" относится к вам, существуют определенные права и обязанности
        относительно вашей персональной информации. Какие категории персональной
        информации мы собираем? За последние двенадцать (12) месяцев мы собрали
        следующие категории персональной информации: [указать соответствующие
        категории персональной информации, собранные в течение последних
        двенадцати (12) месяцев]. Категория Примеры Собрана A. Идентификаторы
        Контактная информация, такая как адрес электронной почты ДА B. Категории
        персональной информации, перечисленные в законе Калифорнии о записях
        клиентов Имя, контактная информация, образование, трудовая деятельность,
        история занятости и финансовая информация НЕТ C. Характеристики,
        охраняемые Калифорнийским или федеральным законодательством Пол и дата
        рождения НЕТ D. Коммерческая информация Информация о транзакциях,
        история покупок, финансовые сведения и информация о платежах НЕТ E.
        Биометрическая информация Отпечатки пальцев и голосовые отпечатки НЕТ F.
        Информация об интернете или другой сетевой активности История
        просмотров, история поиска, поведение в сети, данные об интересах и
        взаимодействие с нашими и другими веб-сайтами, приложениями, системами и
        рекламой НЕТ G. Данные о местоположении Местоположение устройства ДА H.
        Аудио-, электронная, визуальная, термальная, запаховая или аналогичная
        информация Изображения, аудио- и видеозаписи или записи звонков,
        созданные в связи с нашей деловой деятельностью НЕТ I. Профессиональная
        или связанная с занятостью информация Контактная информация о бизнесе
        для предоставления вам наших услуг на бизнес-уровне, должность, а также
        история работы и профессиональные квалификации, если вы подаете заявку
        на работу у нас НЕТ J. Информация об образовании Данные студентов и
        информация из справочника НЕТ K. Выводы, сделанные на основе другой
        персональной информации Выводы, сделанные на основе любой из собранной
        выше персональной информации для создания профиля или резюме о
        предпочтениях и характеристиках отдельного лица НЕТ Мы также можем
        собирать другую персональную информацию вне этих категорий в случаях,
        когда вы взаимодействуете с нами лично, онлайн, по телефону или почте в
        контексте: • Получение помощи через наши каналы поддержки клиентов •
        Участие в опросах или конкурсах для клиентов • Организация оказания
        наших услуг и ответ на ваши запросы Как мы используем и распространяем
        вашу персональную информацию? Дополнительную информацию о нашей практике
        сбора и обмена данных можно найти в настоящей политике
        конфиденциальности. Вы можете связаться с нами по электронной почте по
        адресу info&#64;vivpnxxx.com или воспользоваться контактной информацией,
        указанной внизу этого документа. Если вы используете авторизованного
        агента для осуществления вашего права на отказ, мы можем отклонить
        запрос, если авторизованный агент не предоставит доказательство того,
        что ему было правомерно предоставлено право действовать от вашего имени.
        Будет ли ваша информация передаваться кому-либо еще? Мы можем раскрывать
        вашу персональную информацию нашим поставщикам услуг в соответствии с
        письменным договором между нами и каждым поставщиком услуг. Каждый
        поставщик услуг является коммерческой организацией, которая обрабатывает
        информацию от нашего имени. Мы можем использовать вашу персональную
        информацию для наших собственных деловых целей, таких как внутренние
        исследования в области технологического развития и демонстрации. Это не
        рассматривается как "продажа" ваших персональных данных. ViVpn не
        раскрывала и не продавала никакую персональную информацию третьим лицам
        в течение последних двенадцати (12) месяцев. ViVpn не будет продавать
        персональную информацию, принадлежащую посетителям веб-сайта,
        пользователям и другим потребителям, в будущем. Ваши права в отношении
        ваших персональных данных Право на запрос удаления данных - Запрос на
        удаление Вы можете попросить удалить вашу персональную информацию. Если
        вы просите нас удалить вашу персональную информацию, мы уважим ваш
        запрос и удалим вашу персональную информацию, за исключением некоторых
        исключений, предусмотренных законом, таких как (но не ограничиваясь)
        осуществление другим потребителем своего права на свободу слова, наши
        требования по соблюдению, вытекающие из правового обязательства или
        любая обработка, которая может быть необходима для защиты от незаконных
        действий. Право на информирование - Запрос на получение информации В
        зависимости от обстоятельств, у вас есть право знать: • собираем ли мы и
        используем вашу персональную информацию; • категории персональной
        информации, которую мы собираем; • цели, для которых используется
        собранная персональная информация; • продаем ли мы вашу персональную
        информацию третьим лицам; • категории персональной информации, которую
        мы продали или раскрыли в рамках бизнеса; • категории третьих лиц,
        которым была продана или раскрыта персональная информация в рамках
        бизнеса; и • деловая или коммерческая цель сбора или продажи
        персональной информации. В соответствии с применимым законодательством
        мы не обязаны предоставлять или удалять информацию о потребителе,
        которая была обезличена в ответ на запрос потребителя или для повторной
        идентификации отдельных данных для проверки запроса потребителя. Право
        на недискриминацию при осуществлении прав потребителя в области
        конфиденциальности Мы не будем дискриминировать вас, если вы
        осуществляете свои права в области конфиденциальности. Процесс
        верификации Получив ваш запрос, нам потребуется проверить вашу личность,
        чтобы установить, что вы являетесь тем же человеком, о котором имеется
        информация в нашей системе. Для этого процесса верификации мы попросим
        вас предоставить информацию, чтобы мы могли сопоставить ее с
        информацией, которую вы ранее предоставили нам. Например, в зависимости
        от типа запроса, который вы отправляете, мы можем попросить вас
        предоставить определенную информацию, чтобы мы могли сопоставить ее с
        информацией, которую мы уже имеем в нашем архиве, или мы можем связаться
        с вами посредством средства связи (например, телефона или электронной
        почты), которое вы ранее предоставили нам. Мы также можем использовать
        другие методы верификации в соответствии с обстоятельствами. Мы будем
        использовать предоставленную вами персональную информацию только для
        проверки вашей личности или вашего права на запрос. Возможностью, мы
        будем избегать запроса дополнительной информации от вас для целей
        верификации. Однако, если мы не сможем проверить вашу личность по
        информации, которую уже хранит у нас, мы можем попросить вас
        предоставить дополнительную информацию для целей верификации вашей
        личности и для обеспечения безопасности или предотвращения
        мошенничества. Мы удалим такую дополнительно предоставленную информацию
        сразу же после завершения процесса верификации. Другие права в области
        конфиденциальности • вы можете возражать против обработки ваших
        персональных данных; • вы можете запросить исправление вашей
        персональной информации, если она неверна или больше не актуальна, или
        запросить ограничение обработки данных; • вы можете назначить
        авторизованного агента, чтобы он представлял ваши интересы и подавал
        запрос в соответствии с CCPA от вашего имени. Мы можем отклонить запрос
        от авторизованного агента, который не предоставит доказательства того,
        что ему было правомерно предоставлено право действовать от вашего имени
        в соответствии с CCPA; • вы можете запросить отказ от дальнейшей продажи
        ваших персональных данных третьим лицам. После получения запроса на
        отказ мы выполним запрос как можно скорее, но не позднее 15 дней с даты
        представления запроса. Чтобы воспользоваться этими правами, вы можете
        связаться с нами по электронной почте по адресу info&#64;vivpnxxx.com или
        воспользоваться контактной информацией, указанной внизу этого документа.
        Если у вас есть жалоба на то, как мы обрабатываем ваши данные, мы хотели
        бы услышать вас.&nbsp;
      </p>
      <h2 class="policy-title">
        11. ВНОСИМ ЛИ МЫ ОБНОВЛЕНИЯ В ЭТОТ ОПОВЕЩЕНИЕ?&nbsp;
      </h2>
      <p>
        Коротко говоря: да, мы будем обновлять это оповещение при необходимости,
        чтобы соответствовать соответствующим законам. Мы можем время от времени
        обновлять эту политику конфиденциальности. Обновленная версия будет
        указана датой "Изменено", и она вступит в силу сразу после ее
        доступности. Если мы вносим существенные изменения в эту политику
        конфиденциальности, мы можем уведомить вас, разместив заметное
        уведомление об этих изменениях или направив вам прямое уведомление. Мы
        рекомендуем вам регулярно просматривать эту политику конфиденциальности,
        чтобы быть информированными о том, как мы защищаем вашу информацию. 12.
        КАК ВЫ МОЖЕТЕ СВЯЗАТЬСЯ С НАМИ ПО ВОПРОСАМ, КАСАЮЩИМСЯ ЭТОГО ОПОВЕЩЕНИЯ?
        Если у вас есть вопросы или комментарии касательно этого оповещения, вы
        можете связаться с нашим сотрудником по защите данных (Data Protection
        Officer, DPO), Иванов Олег Владимирович, по электронной почте
        info&#64;vivpnxxx.com. КАК ВЫ МОЖЕТЕ ПРОСМОТРЕТЬ, ОБНОВИТЬ ИЛИ УДАЛИТЬ
        СОБРАННЫЕ У НАС ДАННЫЕ? В соответствии с применимым законодательством
        вашей страны у вас может быть право запросить доступ к персональной
        информации, которую мы собираем от вас, изменить эту информацию или
        удалить ее в некоторых случаях. Чтобы запросить просмотр, обновление или
        удаление вашей персональной информации, отправьте запрос по электронной
        почте на адрес info&#64;vivpnxxx.com.
      </p>
    </div>
  </ng-container>
</div>
