<div class="forgot">
  <div class="logo">
    <img src="/assets/images/logo.png" alt="" />
  </div>
  <form class="form" [formGroup]="form" autocomplete="off" *ngIf="!emailSent">
    <div class="text-center p-pt-3">
      <img src="/assets/images/mail.svg" alt="" />
    </div>
    <div class="form-title">{{ 'forgot.title' | translate }}</div>
    <div class="form-subtitle">{{ 'forgot.instruction' | translate }}</div>
    <div class="invalid-status" *ngIf="message()">{{ message() }}</div>
    <div class="form-wrapper">
      <div class="form-fields">
        <label class="form-fields-label">E-mail</label>
        <input
          name="off"
          class="form-fields-input"
          type="text"
          autocomplete="off"
          formControlName="email"
          placeholder="example@example.com"
        />
      </div>
    </div>
    <div class="submit">
      <div
        class="btn-outline"
        tabindex="0"
        [class.loading]="loading"
        (click)="forgot()"
      >
        <p-progressSpinner
          *ngIf="loading"
          [style]="{ height: '40px', width: '40px' }"
        ></p-progressSpinner>
        {{ 'forgot.send' | translate }}
      </div>
    </div>
  </form>
  <div class="text-center" *ngIf="!emailSent">
    <a class="link" [routerLink]="['/sign-in']">{{
      'forgot.back-to-login' | translate
    }}</a>
  </div>

  <div class="success-sent form" *ngIf="emailSent">
    <div class="text-center p-pt-3">
      <img src="/assets/images/mail.svg" alt="" />
    </div>
    <div class="form-title">{{ 'forgot.check-email' | translate }}</div>
    <div class="form-subtitle">
      {{ 'forgot.recovery-instruction' | translate }}
    </div>

    <div class="submit">
      <div class="btn-outline" tabindex="0" [routerLink]="['/sign-in']">Ok</div>
    </div>
  </div>
</div>
