import { IDeviceWithId } from './device.interface';
import { Language } from './languages.enum';
import { IOrder } from './order.interface';
import { Role } from './roles.enum';

export interface IUser {
  _id: string;
  id?: string;
  email: string;
  password: string;
  language: Language;
  role: Role;
  device?: IDeviceWithId;
  active_order?: IOrder;
  traffic?: number;
  traffic_before?: number;
  used_trial?: boolean;
  /** Недавно был активен */
  has_traffic_recently: boolean;
  /** Disabled by traffic overloaded */
  traffic_disabled: boolean;
}

export type IUserWithoutPassword = Omit<
  IUser,
  'password' | 'has_traffic_recently' | 'traffic_disabled'
>;

export type IUserEmailAndPassword = Pick<IUser, 'email' | 'password'>;

export enum RegistrationError {
  EmailNotValid = 'Email is not valid',
  EmailAlreadyExists = 'Email already exists',
  PasswordNotValid = 'Password is not valid',
  UserNotFound = 'Email or password is not valid',
  Unauthorized = 'Unauthorized',
}
export type IAddUser = Pick<
  IUser,
  'email' | 'password' | 'language' | 'role'
> & {
  order?: Exclude<IOrder, 'user_id'>;
};
