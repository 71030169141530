import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';

import { HeaderComponent } from '../../components/header/header.component';

@Component({
  selector: 'xxx-vpn-eula',
  templateUrl: './eula.component.html',
  styleUrls: ['./eula.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink, HeaderComponent],
})
export class EulaComponent {}
