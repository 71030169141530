import { EProductType, ESourcePayment } from './order.interface';
import { ICost } from './tariff.interface';

export type TInterval = 'Day' | 'Week' | 'Month';

export interface IInvoice {
  id?: string; // идентификатор
  user_id: string; // идентификатор пользователя
  date_start: Date; // дата начала
  date_end: Date; // дата окончания
  source_payment: ESourcePayment; // источник оплаты
  product_type: EProductType; // тип продукта
  price: number; // стоимость на момент оплаты
  currency: ICost; // валюта
  status: boolean; // статус оплаты
  interval: TInterval; // интервал оплаты
  quantity: number; // количество
  token?: string; // токен
}
