import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CloudpaymentService {
  readonly #http = inject(HttpClient);

  getOptions(invoiceId: string) {
    return this.#http.get(`/api/order/cloudpayment_options/${invoiceId}`);
  }
}
