<xxx-vpn-header></xxx-vpn-header>

<div class="info_wrapper">
  <div class="character_wrapper">
    <!-- Описание -->
    <div class="description">
      <div class="label">
        <div>VPN</div>
        {{ 'langing.forviewing' | translate }}
      </div>
      <div class="tags">
        <div class="tag fb">fb</div>
        <div class="tag ig">ig</div>
        <div class="tag tw">tw</div>
        <ng-container *ngIf="currentLanguage() !== 'ru'">
          <div class="tag ph">ph</div>
        </ng-container>
      </div>
      <div class="buy">
        <div class="btn-buy" (click)="scrollToTariffs()">
          <img src="/assets/images/zap.svg" alt="" />
          <span>{{
            'landing.buy'
              | translate : { price: 'tariffs.price.one_year' | translate }
          }}</span>
        </div>
      </div>
    </div>
    <!-- Характеристики -->
    <div class="characteristic characteristic_mobile">
      <div class="item">
        <img class="item-img" src="/assets/images/pocket.svg" alt="" />
        <span>{{ 'landing.safe' | translate }}</span>
      </div>
      <div class="item">
        <img class="item-img" src="/assets/images/lock.svg" alt="" />
        <span>{{ 'landing.private' | translate }}</span>
      </div>
      <div class="item">
        <img class="item-img" src="/assets/images/monitor.svg" alt="" />
        <span>{{ 'landing.devices' | translate }}</span>
      </div>
    </div>
  </div>

  <div class="phones">
    <picture>
      <source
        media="(max-width: 1200px)"
        srcset="assets/images/mobile-vpn.webp"
      />
      <source media="(min-width: 1200px)" srcset="/assets/images/phones.png" />
      <img
        class="phones-image"
        src="assets/images/header.webp"
        alt="Image slide"
      />
    </picture>
  </div>
</div>

<!-- Характеристики -->
<!--<div class="characteristic characteristic_desktop">
  <div class="item">
    <img src="/assets/images/pocket.svg" alt="" width="60">
    <span>{{ "landing.safe" | translate }}</span>
  </div>
  <div class="item">
    <img src="/assets/images/lock.svg" alt="" width="60">
    <span>{{ "landing.private" | translate }}</span>
  </div>
  <div class="item">
    <img src="/assets/images/monitor.svg" alt="" width="60">
    <span>{{ "landing.devices" | translate }}</span>
  </div>
</div>-->
<div class="landing-tariffs">
  <xxx-vpn-tariffs></xxx-vpn-tariffs>
</div>

<!--apps-->
<div class="apps">
  <div class="apps-title">
    {{ 'landing.download' | translate }}
  </div>
  <div class="apps-actions">
    <a
      href="https://apps.apple.com/ru/app/vi-vpn-stay-safe-access-all/id6443472353?l=en-GB"
      target="_blank"
    >
      <img src="/assets/images/apple.png" alt="" />
    </a>

    <a
      href="https://play.google.com/store/apps/details?id=com.vi.vpn"
      target="_blank"
    >
      <img src="/assets/images/google.png" alt="" />
    </a>
  </div>

  <div class="apps-title">
    {{ 'landing.purchases' | translate }}
  </div>

  <div class="apps-methods">
    <a href="#" target="_blank" class="flex flex-row justify-center">
      <img src="/assets/images/apple_pay_logo.png" alt="" />
    </a>

    <a href="#" target="_blank" class="flex flex-row justify-center">
      <img class="h-auto" src="/assets/images/google_pay_logo.png" alt="" />
    </a>

    <ng-container *ngIf="currentLanguage() === 'ru'">
      <a href="#" target="_blank" class="flex flex-row justify-center">
        <img src="/assets/images/cloudpayment.svg" alt="" />
      </a>
    </ng-container>
  </div>

  <div class="apps-terms">
    <a class="apps-terms-link" [routerLink]="['privacy']">{{
      'landing.privacy' | translate
    }}</a>
    <span class="apps-terms-link">x</span>
    <a class="apps-terms-link" [routerLink]="['terms']">{{
      'landing.terms' | translate
    }}</a>

    <ng-container *ngIf="currentLanguage() === 'en'">
      <span class="apps-terms-link">x</span>
      <a class="apps-terms-link" [routerLink]="['eula']">Eula</a>
    </ng-container>
  </div>
</div>
