import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  IUserEmailAndPassword,
  IUserWithoutPassword,
} from '@xxx-vpn/api-interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SignUpService {
  constructor(private http: HttpClient) {
    //
  }

  // функция регистрации пользователя
  registerUser(user: IUserEmailAndPassword): Observable<IUserWithoutPassword> {
    const url = '/api/auth/register';

    return this.http.post<IUserWithoutPassword>(url, user);
  }
}
