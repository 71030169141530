import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  signal,
  WritableSignal,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Router, RouterLink } from '@angular/router';

import { IUserEmailAndPassword } from '@xxx-vpn/api-interfaces';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { finalize, take } from 'rxjs/operators';

import { SignInService } from './sign-in.service';

@Component({
  selector: 'xxx-vpn-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterLink,
    NgIf,
    ProgressSpinnerModule,
  ],
})
export class SignInComponent {
  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });
  message: WritableSignal<string> = signal('');
  loading = false;

  constructor(
    private readonly signInService: SignInService,
    private readonly cdr: ChangeDetectorRef,
    private readonly router: Router,
  ) {}

  auth() {
    if (this.form.invalid) {
      return;
    }
    this.loading = true;
    this.cdr.markForCheck();

    const user = this.form.getRawValue() as IUserEmailAndPassword;

    this.signInService
      .signInUser(user)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.cdr.markForCheck();
        }),
        take(1),
      )
      .subscribe({
        error: ({ error }) => {
          this.message.set(error.message);
        },
        next: (signData) => {
          const token = signData.data?.token;
          if (token) {
            this.router.navigate(['/ru/profile']).catch();
          }
        },
      });
  }
}
