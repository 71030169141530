import { DatePipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router, RouterLink } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { ESourcePayment } from '@xxx-vpn/api-interfaces';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { BehaviorSubject, map, shareReplay, switchMap, tap } from 'rxjs';

import { ProfileService } from './profile.service';

@Component({
  selector: 'xxx-vpn-profile',
  standalone: true,
  imports: [
    ButtonModule,
    RouterLink,
    TranslateModule,
    NgIf,
    DatePipe,
    NgFor,
    ToastModule,
  ],
  providers: [MessageService],
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent {
  readonly #profileService = inject(ProfileService);
  readonly #router = inject(Router);
  readonly #messageService = inject(MessageService);

  #reloadTrigger = new BehaviorSubject<boolean>(true);

  readonly ESourcePayment = ESourcePayment;

  user = toSignal(
    this.#profileService.loadUserMe().pipe(
      map((user) => user.data),
      shareReplay(1),
    ),
  );

  subscription = toSignal(
    this.#reloadTrigger.pipe(
      switchMap(() => this.#profileService.loadUserMe()),
      map((user) => user.data),
      switchMap((user) => this.#profileService.loadUserSubscription(user?.id)),
      map((subscription) => subscription.data),
      shareReplay(1),
    ),
  );

  devices = toSignal(
    this.#reloadTrigger.pipe(
      switchMap(() => this.#profileService.loadUserDevices()),
      map((devices) => devices.data),
      shareReplay(1),
    ),
  );

  unsubscribe() {
    this.#profileService
      .removeSubscription()
      .pipe(
        tap(() => {
          this.#reloadTrigger.next(true);
          this.#messageService.add({
            severity: 'success',
            summary: 'Подписка отменена',
            detail:
              'Когда срок действия подписки истечёт, она не будет продлена',
          });
        }),
      )
      .subscribe();
  }

  removeDevice(device_id: string | null) {
    if (!device_id) return;
    this.#profileService
      .removeDevice(device_id)
      .pipe(
        tap(() => {
          this.#reloadTrigger.next(true);
          this.#messageService.add({
            severity: 'success',
            summary: 'Устройство удалено',
          });
        }),
      )
      .subscribe();
  }

  exit() {
    localStorage.removeItem('token');
    this.#router.navigate(['/']);
  }
}
