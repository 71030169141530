import { IServer } from './server.interface';

export interface IPool {
  id?: string;
  name: string;
  only_premium?: boolean;
  country: string;
  country_flag: string;
  traffic?: number;
  keyCount?: number;
  serverCount?: number;
  activeServerCount?: number;
  maxKeysCount?: number; // максимальное количество ключей
  /** может ли сервер быть автоматически выбран */
  noAutoSelection?: boolean;
}

export interface IPoolWithServers extends IPool {
  servers: IServer[];
}
