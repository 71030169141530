/**
 * Интерфейс для данных о сервере с точки зрения пользователя
 */

export interface IUserServer {
  /** id записи */
  id: string;
  /**  id сервера */
  server_id: string;
  /** id пользователя */
  user_id: string;
  /** активно ли подключение */
  is_active: boolean;
  /** был ли трафик недавно */
  has_traffic_recently: boolean;
  /** хэш */
  hash: string;
  /** дата последнего подключения */
  last_connection?: Date;
  /** трафик */
  traffic: number;
  /** трафик до сброса */
  traffic_before: number;
  /** лимит трафика */
  limit_bytes: number;
  /** количество ошибок */
  errors_count: number;
}
