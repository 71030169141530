export interface IUserStat {
  user_id: string;
  server_id: string;
  traffic: number;
  /** Start of day */
  day: Date;
  /** Hour of record */
  hour: number;
  /** Minute of record */
  minute: number;
  createdAt?: Date;
  updatedAt?: Date;
}
