import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminGuard } from '../guard/admin.guard';
import { AuthGuard } from '../guard/auth.guard';
import { LangGuard } from '../guard/lang.guard';

import { EulaComponent } from './eula/eula.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LandingPage } from './landing/landing.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ProfileComponent } from './profile/profile.component';
import { SignInPage } from './sign-in/sign-in.component';
import { SignUpPage } from './sign-up/sign-up.component';
import { TermsComponent } from './terms/terms.component';
import { CloudpaymentComponent } from './widget/cloudpayment/cloudpayment.component';

const routes: Routes = [
  {
    path: '',
    component: LandingPage,
    canActivate: [LangGuard],
  },
  { path: 'sign-in', component: SignInPage, canActivate: [LangGuard] },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [LangGuard],
  },
  { path: 'sign-up', component: SignUpPage, canActivate: [LangGuard] },
  { path: 'privacy', component: PrivacyComponent, canActivate: [LangGuard] },
  { path: 'terms', component: TermsComponent, canActivate: [LangGuard] },
  { path: 'eula', component: EulaComponent, canActivate: [LangGuard] },
  { path: 'cloudpayment/:invoiceId', component: CloudpaymentComponent },
  { path: 'ru', component: LandingPage, canActivate: [LangGuard] },
  { path: 'ru/sign-in', component: SignInPage, canActivate: [LangGuard] },
  { path: 'ru/sign-up', component: SignUpPage, canActivate: [LangGuard] },
  { path: 'ru/privacy', component: PrivacyComponent, canActivate: [LangGuard] },
  { path: 'ru/terms', component: TermsComponent, canActivate: [LangGuard] },
  {
    path: ':lang/profile',
    canActivate: [AuthGuard, LangGuard],
    component: ProfileComponent,
  },
  {
    path: 'admin',
    canActivate: [AdminGuard, LangGuard],
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  { path: '**', redirectTo: '/sign-in' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
